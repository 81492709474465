// Регулярные части страниц
.right {
  float: right;
}
.header {
  height: 102px;
  padding: 0;
  background-color: #062544;

  @media (max-width: 1200px) {
    height: 90px;
  }

  @media (max-width: 768px) {
    height: unset;
  }

  .headerIn {
    max-width: 1530px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;

    @media (max-width: 1530px) {
      margin: 0 25px;
    }

    @media (max-width: 1200px) {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }

    @media (max-width: 768px) {
      padding: 12px 0;
    }
  }
  //.siteLogo,
  .siteLogoTxt {
    display: inline-block;
    flex: 1;
    height: 90px;
    background: url(../../img/header/siteLogo.png) no-repeat;
    background-size: 320px auto;

    .logoImg {
      display: block;
      width: 320px;
      height: 88px;
      cursor: pointer;
    }

    @media (max-width: 1200px) {
      flex: 1;
      height: 70px;
      background-size: 260px auto;

      .logoImg {
        width: 260px;
        height: 60px;
      }
    }

    @media (max-width: 767px) {
      margin: 0;
      width: 100%;
      background-position: 0 center;
    }
  }
    .right {
      margin: 0 15px;

      @media (max-width: 768px) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  .geoloc {
    display: inline-block;
    width: 8px;
    min-width: 8px;
    height: 13px;
    margin: 44px 1px;
    background: url(../../img/header/geoloc.png) no-repeat;
    background-size: 8px 13px;

    @media (max-width: 767px) {
      margin-bottom: 12px;
    }

    @media (max-width: 1200px) {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
  .location {
    margin-top: -3px;
    color: #ecd1ca;
    display: flex;
    align-items: center;

    @media (max-width: 1200px) {
      margin-bottom: 0;
      margin-top: 0;
      gap: 4px;
    }
  }
  .nickname {
    display: none;
    margin: 18px 12px 0 7px;
    &,
    a {
      color: #ecd1ca;
    }
  }
  .anticon-user {margin: 0 7px 0 2px;}
  .bfButtLogin {
    background: #ecd1ca;
    border-color: #ecd1ca;
    color: #062544;
    &:hover {
      background: #9f886c;
      border-color: #683427;
      color: #ecd1ca;
    }
  }
  .bfButtReg {
    background: transparent;
    border-color: #ecd1ca;
    color: #ecd1ca;
    &:hover {
      background: #9f886c;
      border-color: #683427;
      color: #ecd1ca;
    }
  }
  .logout {
    display: none;
    background: transparent;
    color: #ecd1ca;
    margin-left: -9px;
    margin-right: -3px;
    &,
    &:hover {border-color: transparent;}

    @media (max-width: 1200px) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &.logged {
    .nickname,
    .logout {display: block; margin-left: 12px;}
    .forLogReg {display: none;}
    .nickname {margin: 2px 3px 0 12px;}
  }
  &-ant-dropdown {
    .loginAction {
      width: 100%;
      line-height: 1em;
      font-size: 20px;
      font-variant: all-small-caps;
      border-color: #b49386;
      background-color: #ff4200;
      color: #fff;
      cursor: pointer;
    }
    .ant-dropdown-arrow::before {background-color: #9799a880;}
    .ant-dropdown-menu {
      border-radius: 4px;
      background-color: rgba(57, 73, 88, 1);
      .ant-dropdown-menu-item:hover,
      .ant-dropdown-menu-item.ant-dropdown-menu-item-active {background-color: #062544;}
    }
    &.region .ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled):not(.ant-dropdown-menu-item-danger) .ant-dropdown-menu-title-content,
    &.login .ant-dropdown-menu-title-content,
    &.reg .ant-dropdown-menu-title-content {
      color: #fff;
    }
    &.bt-burg {
      span {
        color: #fff !important;
      }
    }
    &.region .ant-dropdown-menu-item {
      &-disabled .ant-dropdown-menu-title-content {color: #969696;}
      &-danger .ant-dropdown-menu-title-content {color: #f14c4c;}
    }
    &.region .ant-dropdown-menu-item .ant-dropdown-menu-title-content,
    &.login .ant-dropdown-menu-title-content,
    &.reg .ant-dropdown-menu-title-content {
      text-align: center;
      text-decoration: underline;
    }
  }
}

.dropdown {
  display: inline-block;
  height: 46px;
  margin-right: 15px;
  padding-right: 14px;
  line-height: 60px;
  background: url(../../img/dropdown.png) no-repeat 100% 18px;
  background-size: 9px 13px;

  @media (max-width: 1200px) {
    margin-top: 0 !important;
    height: unset;
    line-height: 120%;
    background: url(../../img/dropdown.png) no-repeat 100% 4px;
    background-size: 8px 10px;
  }

  >div {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.2;
  }
}
.loginList {
  padding: 0;
  list-style-type: none;
  text-align: left;
  text-decoration: underline;
  >li {margin: 0.5em;}
  >li:nth-last-child(1) {    margin: 0.75em 0 0;}
  label {
    display: inline-block;
    width: 5em;
  }
  input {
    width: auto;
    -webkit-box-shadow: 0 0 0 50px #c9c294 inset; // проблемно сменить цвет текста! (для сохранённых паролей)
  }
}

.ttLocation.ant-tooltip {
  z-index: 999;
  top: -4px !important; //усиленно поднять, как для -top
  padding-top: 0;
  .ant-tooltip {
    &-content {
      border-radius: 4px;
    }
    &-inner {
      padding: 7px 6px 1px;
      >button {
        margin: -2px 0;
        padding: 0 0.5em;
        border: none;
        background-color: #f77f56;
        &:hover {
          background-color: #fc9675;
        }
      }
    }
    &-arrow { // как для стрелки вниз в .ant-tooltip-placement-top
      top: 10px;
      left: 50%;
      transform: translateY(100%) translateX(-50%);
      .ant-tooltip-arrow-content {
        box-shadow: 3px 3px 7px rgb(0 0 0 / 7%);
        transform: translateY(-11px) rotate(45deg);
      }
    }
  }
}

// banner

.stackBlock.banr {
  max-width: 1100px;
  margin: 0 auto;
  max-height: 260px;
  .ant-carousel .slick-slider {
    max-width: 1100px;
    width: 100vw;
    max-height: 260px;
    height: auto;
  }
}
.stackBlock.banr .ant-image-mask {display: none;}
/*.ant-image-preview-body,
.ant-image-preview-mask {display: none;}
.ant-image-preview-operations-wrapper,
.ant-image-preview-wrap {display: none; height: 0;}

html body {
  width: auto !important;
  overflow-y: auto !important;
}*/

.ant-carousel .slick-list .slick-slide.slick-active {text-align: center;}

.footBlock {
  width: 25%;
  text-align: center;
}
.NoUse.ant-modal.register {
  display: none;
  &.asClient,
  &.asSalon {display: block !important;}
}
.ant-modal.register {
  max-width: 1470px; // 1530px - 60px
  min-width: 340px;
  color: #fbddd3;
  .ant-modal-header {display: none;}
  .ant-modal-close-x,
  .ant-modal-close {width: 18px; height: 18px; line-height: 20px; border-radius: 50%; background-color: gray; opacity: 0.5}
  .ant-modal-content {
    background-color: #1a2835;
    .ant-btn.ant-btn-text span,
    .ant-btn.ant-btn-link span {
      font-size: 16px;
      text-decoration: underline;
      color: #fbddd3;
    }
  }
  .ant-modal-body {
    padding: 0.5em;
    border: 1px solid #fbddd3;
    border-radius: 3px;
  }
  .ant-modal-footer {padding-bottom: 0; border-top-color: transparent;}
  .dialog {
    display: flex;
    flex-direction: column;
    margin: -0.625em 0 0;
    padding: 0 0.75em;
    text-align: center;
    &.hide {display: none;}
  }
  .dialogHead {
    line-height: 1.45;
    font-size: 2.75em;
    white-space: nowrap;
    font-variant-caps: all-small-caps;
    border-bottom: 2px solid #d5c1ba;
  }
  .dialogBody {
    display: flex;
    flex-direction: column;
    min-height: 170px;
    justify-content: space-around;
    align-self: center;
    font-size: 16px;
    .dialogBodyIn {
      margin-bottom: 0;

      .ant-checkbox-wrapper {
        color: #fbddd3;
        text-align: left;
      }

      .acceptPrivacyLink {
        color: #fbddd3;
        text-align: center;
        text-decoration: underline;
      }
    }
    input {
      margin: 0.5em auto;
      font-size: 24.5px;
      background-color: #293c4d;
      border: 1px solid transparent;
      &:hover {border-color: #666;}
      &:focus {border-color: #666;}
    }
    .pwds input {
      width: 95%;
      margin: 0.5em auto;
      padding: 0.25em 0.5em;
      letter-spacing: 1px;
      font-size: 18px;
      border: 1px solid #fbddd3;
      border-radius: 2px;
      background-color: #293c4d;
      &:focus {outline: none;}
    }
    .digits,
    .phone {
      margin-bottom: 0;
      font-size: 23px;
      .ed {
        display: inline-flex;
        i {
          display: inline-flex;
          overflow: hidden;
          width: 1.125ex;
          height: 1.14em;
          margin: 0 0.125em;
          white-space: nowrap;
          line-height: 1.1;
          text-align: end;
          align-self: flex-end;
          font-style: normal;
          border-bottom: 1px solid;
        }
        &:focus {outline: none;}
      }
      .phoneCountry {width: 3ex;}
      .phoneDigits4,
      .phoneNumber {
        width: 17ex;
        background-color: transparent;
        &:focus {outline: #6a6c78}
      }
      .phoneDigits4 {width: 6ex; text-align: center;}
    }
    .digits {
      margin-top: 0.5em;
      .ed {
        i {
          width: 1.75em;
          height: 1.75em;
          margin: 0 0.375em;
          padding: 0.25em 0 0.25em 0.5625em;
          white-space: nowrap;
          line-height: 1.3;
          text-align: center;
          border: 1px solid;
          border-radius: 2px;
          background: #293c4d;
        }
      }
    }
    .note {
      color: #FF0000;
      font-weight: bold;
      font-size: 19px;
    }
    .lat, .ge7, .cap, .dig {
      font-weight: bold;
      font-size: 19px;
      &:not(.note) {color: #00FF00;}
    }
  }
  .step4 dd {
    color:  #ff4200;
  }
  .srchAction:not(.ant-btn-link):not(.ant-btn-text) {
    width: 100%;
    margin: 0.375em auto 0.375em;
    line-height: 1;
    font-size: 21px;
    border-radius: 2px;
    border-color: #ceb9b4;
    color: #fbddd3;
    &:hover {
      background-color: #ef511a;
    }
  }
}

.bt-header__burger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 36px;
  height: 32px;

  span {
    height: 4px;
    width: 36px;
    min-width: 36px;
    background-color: #ff4200;
  }
}

.bs_login_popover {
  color: #fff !important;

  &::after {
    background: rgb(57, 73, 88);
  }

  .ant-popover-arrow-content {
    --antd-arrow-background-color: rgb(57, 73, 88);
  }

  .ant-popover-inner {
    background: rgb(57, 73, 88);
  }

  >li {margin: 0.5em;}
  >li:nth-last-child(1) {    margin: 0.75em 0 0;}
  label {
    display: inline-block;
    width: 5em;
  }
  input {
    width: auto;
    -webkit-box-shadow: 0 0 0 50px #c9c294 inset; // проблемно сменить цвет текста! (для сохранённых паролей)
  }

  .loginList {
    padding: 0;
    list-style-type: none;
    text-align: left;
    text-decoration: underline;
    >li {margin: 0.5em;}
    >li:nth-last-child(1) {    margin: 0.75em 0 0;}
    label {
      display: inline-block;
      width: 5em;
      color: white;
    }
    input {
      width: auto;
      -webkit-box-shadow: 0 0 0 50px #c9c294 inset; // проблемно сменить цвет текста! (для сохранённых паролей)
    }
  }

  .loginAction {
    width: 100%;
    line-height: 1em;
    font-size: 20px;
    font-variant: all-small-caps;
    border-color: #b49386;
    background-color: #ff4200;
    color: #fff;
    cursor: pointer;
  }
  .ant-dropdown-arrow::before {background-color: #9799a880;}
  .ant-dropdown-menu {
    border-radius: 4px;
    background-color: rgba(57, 73, 88, 1);
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-item.ant-dropdown-menu-item-active {background-color: #062544;}
  }
  &.region .ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled):not(.ant-dropdown-menu-item-danger) .ant-dropdown-menu-title-content,
  &.login .ant-dropdown-menu-title-content,
  &.reg .ant-dropdown-menu-title-content {
    color: #fff;
  }
  &.bt-burg {
    span {
      color: #fff !important;
    }
  }
  &.region .ant-dropdown-menu-item {
    &-disabled .ant-dropdown-menu-title-content {color: #969696;}
    &-danger .ant-dropdown-menu-title-content {color: #f14c4c;}
  }
  &.region .ant-dropdown-menu-item .ant-dropdown-menu-title-content,
  &.login .ant-dropdown-menu-title-content,
  &.reg .ant-dropdown-menu-title-content {
    text-align: center;
    text-decoration: underline;
  }

  .forgot_pswd_log_popover {
    text-align: center;
    text-decoration: underline;
    color: white;
    display: inline-block;
    width: 100%;
    cursor: pointer;
  }
}
