.wrapper {
  border-radius: 4px;
  background-color: #1a2835;
  position: relative;
  display: flex;
  color: #FBDDD3;
  margin-bottom: 20px;

  .btnWrapper {
    margin-top: 14px;
    display: flex;
    gap: 12px;
    justify-content: flex-end;

    .btn {
      color: white;
      background-color: #ff4200;
      border-color: transparent;

      &:hover, &:focus {
        background-color: #a72f02;
        border-color: transparent;
      }
    }
  }
}

.btnConfirm {
  background-color: #e32d0d;
  border-color: transparent;

  &:hover, &:focus {
    background-color: #951d09;
    border-color: transparent;
  }
}

.logo {
  width: 180px;
  min-width: 180px;
  height: 230px;

  img {
    width: 180px;
    height: -webkit-fill-available;
    object-fit: cover;
  }

  &.contentMaster {
    width: 320px;
    min-width: 320px;
    height: unset;

    img {
      width: 320px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;

  &.contentMaster {
    .aboutList .aboutItem {
      flex-basis: 30%;
      margin-right: 1.1%;
    }
  }

  .aboutList {
    display: flex;
    flex-wrap: wrap;
    font-size: 18px;

    .aboutItem {
      display: flex;
      flex-direction: column;
      gap: 4px;
      flex-basis: 21%;
      margin-right: 1%;
      margin-bottom: 14px;
    }

    .aboutText {
      opacity: 0.6;
      font-size: 14px;
      color: white;
    }

    .aboutValue {
      font-size: 20px;
      color: #FBDDD3;
    }
  }

  &:not(.contentMaster) {
    .aboutText {
      font-size: 14px;
    }

    .aboutValue {
      font-size: 18px;
    }
  }
}

.fio {
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 6px;
}

.descWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .descTitle {
    opacity: 0.6;
    font-size: 14px;
    color: white;
  }

  .descValue {
    font-size: 20px;
    color: #FBDDD3;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    box-orient: vertical;
  }

  &.descShort {
    .descValue {
      -webkit-line-clamp: 1;
      line-clamp: 1;
    }
  }
}

.categoriesList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 12px;
  align-items: center;
}

.loyality {
  display: flex;
  gap: 6px;
  align-items: center;

  &Items {
    display: flex;
    gap: 6px;
  }

  &Item {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #FBDDD3;

    &.selected {
      background-color: #e32d0d;
    }
  }

  &Text {
    font-size: 18px;
    color: #FBDDD3;
  }
}
