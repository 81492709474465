.r-on-map {
  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(100, 100, 100, 0.2);
    backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__inner {
    width: calc(100vw - 160px);
    height: calc(100vh - 160px);
    background-color: #283a4f;
    border-radius: 4px;
    display: flex;
    overflow: hidden;

    @media (max-width: 767px) {
      width: calc(100vw - 20px);
      height: calc(100vh - 20px);
    }

    h3 {
      padding: 0;
      margin: 0 0 12px;
      font-size: 18px;
      font-weight: bold;
      color: white;
    }
  }

  &__controls {
    width: 320px;
    padding: 20px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  &__tags-list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-item {
      display: flex;
      gap: 4px;
      align-items: center;

      .r-on-map__tag {
        padding: 0 12px;
        color: white;
        font-size: 16px;
        border-radius: 4px;

        &-text {
          color: #FBDDD3;
          font-size: 16px;
        }

        &.green {
          background-color: #03B821;
        }

        &.blue {
          background-color: rgb(3, 139, 255);
        }

        &.red {
          background-color: red;
        }
      }
    }
  }

  &__filters {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 160px - 80px - 132px);
    margin-top: 40px;
    position: relative;
  }

  &__map {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    color: white;
    position: relative;

    &-control {
      z-index: 2;
      position: absolute;
      color: #333333;
      background-color: white;
      padding: 8px;
      border-radius: 4px;
      box-shadow: 0 1px 3px 0 rgba(38, 38, 38, 0.5);
      font-size: 10px;
      font-weight: bold;
      cursor: pointer;

      &.text {
        padding: 6px 8px;
        font-size: 13px;
      }

      &.back {
        top: 10px;
        right: 52px;
      }

      &.filter {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 0 8px 0 0 !important;
      }

      &__list {
        display: flex;
        position: absolute;
        gap: 32px;
        left: 50%;
        transform: translateX(-50%);
        top: 10px;
        z-index: 2;
        pointer-events: none;
        user-select: none;

        @media (max-width: 767px) {
          display: none;
        }

        .r-on-map__map-control {
          position: static;
          pointer-events: all;
        }

        .r-on-map-c-tag {
          height: 32px;
          width: 32px;
          border-radius: 4px;

          &.green {
            background-color: #03B821;
          }

          &.blue {
            background-color: rgb(3, 139, 255);
          }

          &.red {
            background-color: red;
          }
        }
      }
    }
  }
}
