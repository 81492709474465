.App {
  text-align: center;
}
:root ::selection {
  color: #eee;
  background: #c17b54;
}
.siteLogo {
  pointer-events: none;
}
body >iframe[style*="2147483647"] {display: none !important;z-index: -1;height: 0; position: absolute;} // реактовские заскоки с блокирующим прозрачным фреймом

@media (prefers-reduced-motion: no-preference) {
  .siteLogo {
    //animation: App-logo-spin 16s 1 linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(-188deg);
  }
  to {
    transform: rotate(0deg);
  }
}
