.wrapper {
  background-color: #1a2835;

  [class*="ant-input"], [class="ant-input ant-input-focused"], [class*="ant-input-status-error"] {
    background-color: #81919f !important;
    border-color: black !important;
    color: white !important;

    &:focus {
      background-color: #81919f;
      border-color: black;
      color: white;
    }
  }
}

.inner {
  max-width: 1470px;
  margin: 40px auto;
  padding: 0 25px;
  color: white;
}

.title {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: 32px;
  text-decoration: underline;
}

.content {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
  }
}

.contacts_data {
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: 900px) {
    width: 100%;
  }
}

.contact_item {
  display: flex;
  flex-direction: column;
}

.name {
  color: white;
  text-transform: uppercase;
  font-size: 21px;
  text-decoration: none;

  @media (max-width: 900px) {
    font-size: 16px;
    word-break: break-all;
  }
}

.value {
  font-size: 30px;
  color: #ff4200;

  @media (max-width: 900px) {
    font-size: 22px;
    word-break: break-all;
  }

  &:hover {
    color: #ff4200;
  }
}

.form_wrapper {
  min-width: 460px;
  width: 460px;

  @media (max-width: 900px) {
    min-width: 0;
    width: 100%;
  }

  .btn {
    background-color: #FF4200;
    border: 1px solid #FF4200;

    span {
      text-decoration: underline;
    }

    &:hover {
      background-color: #ca3403;
      border: 1px solid #ca3403;
    }

    &:focus {
      background-color: #ca3403;
      border: 1px solid #ca3403;
    }
  }
}

.form_title {
  font-size: 21px;
  color: white;
  text-transform: uppercase;
  display: block;
  margin-bottom: 16px;
}
