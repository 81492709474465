.wrapper {
  background-color: #062544;
}

.inner {
  margin: 0 auto;
  max-height: 1530px;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: #fbddd3;

  @media (max-width: 1200px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 20px;
    font-size: 14px;
  }

  @media (max-width: 767px) {
    gap: 18px;
    font-size: 12px;
    padding: 20px;
  }
}

.menu {
  display: flex;
  gap: 12px;

  a {
    color: #fbddd3;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 4px;
  }
}

.metric {
  margin: 0 auto;
  max-height: 1530px;
  padding: 40px;
  padding-top: 0;

  @media (max-width: 767px) {
    padding: 20px;
    padding-top: 0;
  }
}
