.personalFee {
  display: flex;
  align-items: center;
  gap: 8px;

  &Button {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }
}

.btnConfirm {
  background-color: #e32d0d;
  border-color: transparent;

  &:hover, &:focus {
    background-color: #951d09;
    border-color: transparent;
  }
}
