// Дополнение ЛК  для менеджера салона(Личного Кабинета)

.ant-layout.salonMan {
  .pricelist .servByCat.servNoHeadNoFoot,
  :root #root & .stackIn.bfCarou-hider,
  :root #root & >.ant-layout-has-sider {
    max-width: 1552px;
    width: 100%;
  }
  .header .headerIn {
    max-width: 1552px;
    width: calc(100% - 20px);
  }
  .stackBlock.bacVw100 > .stackIn {
    max-width: 1552px;
    width: calc(100% - 20px);
  }
  .stackIn.bfCabinet {
    width: 100%;
    max-width: 1552px;
    h2 {padding: 0 0.875em;}
  }
  .stackBlock {
    width: 100%;
    >div {text-align: left;}
    &.pricelist {
      max-width: 1552px;
      text-align: left;
      background-color: #293c4d; // серый на тёмном
      h2 {padding: 0.5em;}
    }
  }
  .stackBlock.bfCabinet.tabs {
    margin: 2em 0 0;
  }
  .salonManData .salonTitle {
    max-width: none;
    margin-top: 0.5em;
    margin-bottom: 0;
    font-size: 24px;
    font-weight: bold;
    border-bottom: none;
    &.editable {
      margin-top: 0;
      margin-bottom: 0;
      input {font-size: 20px;}
    }
  }
  .rowCityTranspAddrTel {
    .phone {
      max-width: 182px;
      margin: 0.875em 0.5em;
    }
  }
  .salonPhotoStars {
    margin: 1.75em 60px 0 10px;
    .salonPhoto {position: relative;}
    .ant-upload-list-picture-card { // программный клик по невидимой кнопке в этом блоке
      overflow: hidden;
      width: 0;
      height: 0;
    }
  }
  .salonManPortfolio,
  .salonManData {
    width: calc(1552px - 157px - 70px); // =1325px
    .ant-btn.toMap,
    .ant-btn.orange {
      padding: 0 2.5em;
      //font-weight: bold;
      text-decoration: underline;
      color: #fff;
      .setStar {
        display: inline-block;
        .footBlock {
          padding: 0 0.5em;
        }
      }
      //&:first-child {margin-top: 0.25em}
    }
    .descrTitle {font-size: 14px;}
    .ant-upload.ant-upload-select-picture-card,
    .ant-upload-list-picture-card-container {
      display: inline-block;
      width: 316px;
      height: auto;
      margin: 0 8px 8px 0;
      vertical-align: top;
    }
    .rowDescr .toRight {
      position: relative;
      right: 1.5em;
    }
    .descr textarea {
      border: 1px solid #606060;
      background-color: transparent;
      color: rgb(251, 221, 211);
      &:focus {border-color: #919191; box-shadow: 0 0 0 2px rgba(255, 77, 24, 0.2);}
    }
    .ant-upload.ant-upload-select-picture-card {
      height: 218px;
      background-color: #5a6270;
      &:hover {border-color: #dd8749;}
      >.ant-upload {color: #fff;}
    }
    .ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {
      padding: 0;
    }
    .editNote,
    .label {font-size: 14px;}
    .rowCityTranspAddrTel .city {display: inline-block;}
    .ant-btn {
      &.toMap {
        position: relative;
        top: 4px;
        display: inline-block;
        height: 1.5em;
        margin-bottom: -1.375em;
        margin-left: auto;
        padding: 0;
        text-align: right;
        font-size: 12px;
        span {text-decoration: underline;}
      }
    }
    .dummy-prev-pasw {width: 0; padding: 0; opacity: 0;}
  }
  .salonManPortfolio {
    .ant-upload.ant-upload-select-picture-card,
    .ant-upload-list-picture-card-container {
      display: inline-block;
      width: 200px;
      height: 200px;
      margin: 0 8px 8px 0;
      vertical-align: top;
    }

  }
  .pricelist {
    .servByCat.tab {
      display: block;
      //margin-top: -2em;
      &.active {display: block;}
      .servCat {
        columns: 439px;
        column-gap: 20px;
        column-rule: 1px outset #c7b2af;
        text-align: left;
      }
    }
    .ant-modal-content {
      padding: 0.25em 1em 2.375em;
      background-color: #293c4d;
      box-shadow: none;
    }
    .servByCat.servNoHeadNoFoot.tab.active ~ .srchSalonDateTimeOver {
      .srchSalonDateTime {
        margin-bottom: -0.125em;
      }
      >.ant-modal-content {
        padding: 0;
        >.ant-modal-body {padding: 0 0 1em;}
      }
    }
    .stackIn.bfCarou-hider > h2 {
      margin: 0.25em 0 0;
      text-align: left;
      font-size: 1.5em;
      font-weight: bold;
      letter-spacing: 1px;
      color: #fbddd3;
    }
  }

  .stackIn.bfCabinet.salonAccount {margin: 0 auto;}
}
.img-crop-modal.pictureEdit {
  .ant-modal-content {
    background-color: #777;
    .ant-modal-close,
    .ant-modal-title {color: #bbb;}
  }
  .ant-modal-header {
    border-bottom-color: #8d8d8d;
    background-color: #444;
  }
  .ant-modal-footer {
    border-top-color: #888;
    background-color: #444;
    button {
      border-radius: 2px;
      border-color: #c5c5c5;
      &.ant-btn-default {
        background-color: #aaa;
      }
      &.ant-btn-primary {
        border-color: #7a5049;
        background-color: #7a3429;
        color: #cbb3ac;
        &:hover {
          border-color: #72544f;
          background-color: #8d463b;
          color: #d3c4c0;
        }
      }
      &:hover {
        color: #ab5940;
      }
    }
  }
  .ant-slider-rail {
    background-color: #aaa;
  }
  .ant-slider-handle {
    background-color: #ddd;
  }
}


