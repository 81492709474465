.rateItem {
  color: #FF4200;
  margin-left: 16px;
}

.wrapper {
  display: block;

  [class*="ant-table-tbody"] > tr > td {
    border-bottom: #293C4D;
  }

  .header {
    display: flex;

    .calendarWrapper {
      display: flex;
      gap: 40px;
      align-items: center;
      margin: 0 auto;

      .arrow {
        height: 50px;
        width: 20px;
        padding: 12px;
        background-image: url("../../../img/carouArrowRight.png");
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;

        &Left {
          margin-right: -20px;
          transform: scaleX(-1);
        }

        &Right {
          margin-left: -20px;
        }
      }

      .disabledCalendar {
        *[class*="ant-picker-cell-selected"] {
          *[class*="ant-picker-cell-inner"] {
            background-color: transparent;
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
    }
  }

  &_colored {
    background-color: #293C4D;
    padding: 20px;
  }

  .calendar {
    width: 300px;
    border-radius: 2px;

    .calendarText {
      font-size: 16px;
      color: #ffffff;
      text-align: center;
    }
  }
}

.subTitle {
  font-weight: bold;
  font-size: 2em;
  color: #FCDED4;
}

.eventsWrapper {
  margin-bottom: 20px;

  .eventsHeader {
    display: flex;
    justify-content: space-between;
    background-color: red;
    color: white;
    font-weight: bold;
    padding-left: 30px;
    padding-right: 20px;
  }
}

.btn {
  background-color: #FF4200;
  border: 1px solid #FF4200;

  &:hover {
    background-color: #ca3403;
    border: 1px solid #ca3403;
  }

  &:focus {
    background-color: #ca3403;
    border: 1px solid #ca3403;
  }
}

.schedulerWrapper {
  display: flex;
  box-sizing: border-box;
  padding-bottom: 100px;

  .leftColumn {
    width: 160px;

    .row {
      height: 100px;
      display: flex;
      border: 1px solid #FBDDD3;
      align-items: center;
      border-bottom-width: 4px;

      &.column {
        flex-direction: column;
        gap: 12px;
      }

      &Centered {
        justify-content: center;
      }

      .hTitle {
        font-weight: bold;
        font-size: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 104px;
        user-select: none;
      }

      .hours {
        height: 100px;
        user-select: none;

        &Item {
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #FBDDD3;
          width: 56px;
        }
      }
    }
  }

  .rightColumn {
    overflow-x: auto;
    display: flex;

    .rightRow {
      width: 220px;
      position: relative;
    }

    .clientAlarm {
      background-color: red;
      border-radius: 10px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: absolute;
      top: 4px;
      left: 4px;
      user-select: none;
      font-weight: bold;
    }

    .eventsList {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .event {
      position: absolute;
      left: 0;
      right: 0;
      background-color: #293C4D;
      border: 1px solid #293C4D;
      color: white;
      overflow: hidden;
      text-align: center;
      transition: height .3s ease-out, box-shadow .3s ease-out;

      &.haveAccord:hover {
        height: fit-content !important;
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
        z-index: 2;
      }

      .evDisabledText {
        font-size: 20px;
        text-align: center;
      }

      .eventEdit {
        position: absolute;
        top: 4px;
        right: 4px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: rgb(3, 139, 255);
        cursor: pointer;
        background-image: url('../../../img/textEdit.png');
        background-size: 10px 10px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        opacity: 0;
        transition: opacity .4s ease-out;
        pointer-events: none;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);

        &.delete {
          right: 28px;
          background-color: red;
          background-image: none;
          font-size: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &:hover {
        .eventEdit {
          opacity: 1;
          pointer-events: unset;
        }
      }

      .eventPt {
        margin-top: 30px;
      }

      .b {
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .rightCol {
      width: 220px;
      height: 100px;
    }

    .rightColRow {
      height: 25px;
      background-color: #FBDDD3;
      border: 1px solid #293C4D;
    }

    .rightRowWrapper {
      display: flex;
      justify-content: space-between;

      &:last-of-type .rightColRow {
        border-bottom-width: 4px;
        border-bottom-color: #293C4D;
      }
    }

    .rightRHead {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid #FBDDD3;
      border-bottom-width: 4px;
      border-bottom-color: transparent;

      .serviceName {
        border-top: 1px solid #FBDDD3;
        color: #FBDDD3;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0 6px;
          text-align: center;
        }

        span:not(:last-of-type) {
          border-right: 1px solid #FBDDD3;
        }
      }

      .colHead {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 3;

        .masterName {
          color: #FBDDD3;
          font-size: 24px;
          white-space: nowrap;
          width: 200px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .rowButtons {
          display: flex;
          width: 100%;
          justify-content: space-around;
        }

        .rowBtn {
          color: white;
          cursor: pointer;
        }
      }
    }
  }
}

.loaderWrapper {
  display: flex;
  height: 200px;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: white;
}

[class*="ant-row--PLANNED"] {
  background-color: rgb(3, 139, 255);
  color: white;

  &:hover, [class*="ant-table-cell"]:hover, [class*="ant-table-cell-row-hover"] {
    background-color: rgb(3, 139, 255) !important;
  }
}

[class*="ant-row--DONE"] {
  background-color: #1a2835;
  color: white;

  &:hover, [class*="ant-table-cell"]:hover, [class*="ant-table-cell-row-hover"] {
    background-color: #1a2835 !important;
  }
}

[class*="ant-row--IN_PROGRESS"] {
  background-color: #03B821;
  color: white;

  &:hover, [class*="ant-table-cell"]:hover, [class*="ant-table-cell-row-hover"] {
    background-color: #03B821 !important;
  }
}
