// Home scss
.ant-layout {
  .stackBlock {
    overflow: hidden;
    margin: 0 25px;
    text-align: center;

    @media (max-width: 768px) {
      margin: 0;

      &.bfSearch {
        padding: 0 15px;
      }
    }

    @media (max-width: 768px) {
      overflow: unset;
    }
  }
  &.home .stackIn.bfCabinet >h2 {
    margin: 0.25em 0 0.0625em;
    text-align: center;
    font-size: 3.5em;
  }
  &.home .splide__progress__bar {
    height: 1px;
    background-color: #666;
  }
  .stackIn {
    position: relative;
    display: inline-block;
    //margin: 0 auto;
    //text-align: center;
    >img {width: 100%;}
    .sloganHtml {
      position: absolute;
    }
    .everyColorize { // баннер "Раскрасим каждую"
      right: 46px;
      top: 10%;
      width: 35%;
      line-height: 1.2;
      font-size: 4.125em;
      font-weight: bolder;
      color: #062544;
    }
    :root &.bfCarou-hider {
      overflow: hidden;
      max-width: 1470px; // 1530px - 60px

      @media (min-width: 1470px) {
        margin: 0 auto;
      }

      >h2 {
        margin: 0.25em 0 0;
        text-align: center;
        font-size: 3.5em;
        font-weight: bold;
        letter-spacing: 1px;
        color: #fbddd3;

        @media (max-width: 768px) {
          font-size: 2em;
        }
      }
    }
  }
  .stackBlock.advaSite {
    padding: 4px 0;
    text-align: center;
    background-color: #293c4d;
  }
  .advaSiteIn {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 1530px;
    width: 100%;
    color: #fbddd3;
    .advaSiteEl {
      margin: 0 1em;
      dt {
        line-height: 1.1;
        font-size: 52px;
        font-weight: bold;

        @media (max-width: 1200px) {
          font-size: 30px;
          line-height: 120%;
        }
      }
      dd {
        line-height: 1.05;
        font-size: 20px;
        text-transform: uppercase;
        color: #fff;

        @media (max-width: 1200px) {
          font-size: 14px;
          line-height: 120%;
        }
      }
    }
  }
}
// ====================== Поиск по параметрам =====================
.bfSearch {
  font-size: 16px;
}
.srchTimeExact,
.srchDescr,
.srchForm {
  position: relative;
  z-index: 1;
  max-width: 1310px;
  margin: 0 auto;
  color: #fbddd3;

  &.srchPriceBts {
    max-width: unset;
  }
}
.tabMask.down {position: relative; z-index: 2; height: 4.375em; margin-bottom: -4.375em; cursor: not-allowed;}
.srchDescr {
  max-width: 1530px;
  padding: 0 0.7em;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }
}
.srchTabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 10px);
  margin: 1.375em 5px 0.625em;
  border: 1px solid #fbddd3;
  border-radius: 0.85em;
  font-size: 20px;
  font-variant-caps: all-small-caps;
  font-weight: bold;

  @media (max-width: 1200px) {
    margin: 0 auto 5px auto;
    width: fit-content;
  }

  label {
    width: 20%;
    background-color: transparent;
    color: #fbddd3;

    @media (max-width: 1200px) {
      width: auto;
    }

    &:hover {
      border-color: rgba(214, 181, 169, 0.46);
      background-color: rgba(214, 181, 169, 0.46);
      color: #d6b5a9;
    }
    &:first-child {
      border-top-left-radius: 0.9em;
      border-bottom-left-radius: 0.9em;
      border-right-color: transparent !important;
    }
    &:last-child {
      border-top-right-radius: 0.9em;
      border-bottom-right-radius: 0.9em;
    }
  }
  label.ant-radio-button-wrapper {
    font-size: 20px;
    white-space: nowrap;
    font-variant-caps: all-small-caps;
    font-weight: bold;
    border-color: transparent;
    &:not(:first-child)::before {width: 0;}
    @media (max-width: 1200px) {
      font-size: 14px;
      white-space: normal;
    }

    @media (max-width: 860px) {
      font-size: 12px;
    }

    @media (max-width: 768px) {
      padding: 0 7px;
    }
  }
  label.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: #fbddd3;
    background-color: #fbddd3;
    color: #1a2835;
    &:hover {
      border-color: #fbddd3;
      background-color: #d6b5a9;
      color: #544733;
    }
  }
}
.pricelist .servByCat.servNoHeadNoFoot,
.master .servByCat.servNoHeadNoFoot,
.ant-modal.servNoHeadNoFoot {
  max-width: 1470px; // 1530px - 60px
  min-width: 340px;
  width: auto !important;
  color: #fbddd3;

  @media (max-width: 767px) {
    min-width: unset;
  }

  .ant-modal-header {display: none;}
  .ant-modal-close-x,
  .ant-modal-close {width: 18px; height: 18px; line-height: 20px; border-radius: 50%; background-color: gray; opacity: 0.5}
  .ant-modal-content {background-color: #1a2835;}
  .ant-modal-body {padding: 0.5em;}
  .ant-modal-footer {padding-bottom: 0; border-top-color: transparent;}
  h2.servTabCat {
    margin: 0.25em 0;
    text-align: center;
    text-decoration: underline;
    font-size: 18px;
    color: #fff;
  }
  .servCat {
    columns: 439px;
    //margin: 0 1.75em;
    column-gap: 20px;
    column-rule: 1px outset #c7b2af;

    @media (max-width: 1200px) {
      columns: unset;
      column-gap: unset;
      column-rule: unset;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .serv {
      margin: 0 1.75em;

      @media (max-width: 1200px) {
        flex-basis: calc(50% - 10px);
        margin: 0;
      }

      @media (max-width: 767px) {
        flex-basis: 100%;
      }
    }
    .servTiming,
    .servPrice {float: right;}
    .servValue {
      margin-left: 0.7em;
      //font-size: 14px;
      color: #fbddd3;
    }
    .servTiming {margin-left: 1em;}
    .ant-checkbox-inner {
      width: 13px;
      height: 13px;
      border-color: #fcded4;
      border-radius: 4px;
      background-color: #283e4c;
    }
    sub {bottom: 0; font-size: 11px; color: #cdb5a7;}
  }
}
.srchSalonDateTime,
.srchServiceExtra,
.srchServiceExtraTwo,
.srchServiceExtraThree {
  display: flex;
  height: 6em;
  margin-top: -1px;

  @media (max-width: 1200px) {
    height: auto;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
}
.srchSalon,
.srchDate,
.srchTime,
.srchService,
.srchExtra {
  width: 33%;
  margin: 2px;
  padding: 0.375em 1.7em;
  line-height: 2.5em;
  text-align: left;
  background-color: #293c4d;

  @media (max-width: 1200px) {
    padding: 0.1em 0.6em;
    line-height: 140%;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 2px 0;
  }

  &.noSrch {box-shadow: inset 0 0 0 3px #ff4200;}
  &.yesSrch {box-shadow: inset 0 0 0 3px #009800;}
}
.srchDateTitle {white-space: nowrap;}
.srchCityTitle,
.srchDateTitle,
.srchTimeTitle {
  font-size: 16px;
  margin-bottom: 0.25em;

  @media (max-width: 1200px) {
    line-height: 140%;
    font-size: 14px;
    margin-bottom: 0.4em;
  }
}
.srchExtraButton {
  font-size: 48px;
  font-weight: bold;
  color: #f9ddd1;
  &Text {
    font-size: 18px;
    font-weight: bold;
    color: #f9ddd1;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
}
.srchService {
  width: 100%;
  margin: 3px;
  padding: 0.375em 1.7em;
  line-height: 2.5em;
  text-align: left;
  background-color: #293c4d;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {box-shadow: 0 0 0 1px gray; background-color: #2a3f51;}

  @media (max-width: 1200px) {
    line-height: 140%;
    font-size: 14px;
    padding: 0.1em 0.6em;
  }

  @media (max-width: 768px) {
    margin: 3px 0;
  }
}

.sex >div:not(:first-child),
.srchCitySelect >div,
.srchDateSelect >div,
.srchServiceRequest >div {
  line-height: 1.45em;
  padding-left: 3px;
  border-bottom: 2px solid #fbddd3;
  color: #fff;

  @media (max-width: 1200px) {
    line-height: 120%;
  }

  &:hover {
    border-color: gray;
    border-bottom-color: #fbddd3;
  }
  .ant-select-arrow {
    color: gray;
  }
}
.srchCitySelect >div {padding-left: 0;}
.srchTimeExact {float: right; label {color: #fbddd3;}}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #d8381d;
  border-color: #e32f11;
}
.ant-checkbox-inner {
  background-color: transparent;
}
.ant-checkbox-checked::after,
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #ff2500;
}
.master .servByCat.servNoHeadNoFoot,
.ant-modal.servNoHeadNoFoot {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #008100;
    border-color: #008100;
    border-radius: 2px;
  }
  .ant-checkbox-inner {
    background-color: transparent;
  }
  .ant-checkbox-checked::after,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #009800;
  }
}

.selects-wrapper {
  display: flex;
}

.ant-picker.srchDateSelect, // ===== дейтпикер, таймпикер, редизайн под страницу =====
.srchTimeSelect {
  width: 100%;
  border-color: transparent;
  background-color: transparent;
  top: -0.875em;
  height: 2.5em;
  padding: 4px 1px;
  color: #fbddd3 !important;
  border-bottom: 2px solid #fbddd3 !important;

  &.ant-select-focused {
    border-color: transparent !important;
    background-color: transparent !important;
    border-bottom: 2px solid #fbddd3 !important;

    .ant-select-selector {
      background-color: transparent !important;
      border-color: transparent !important;
      border: none !important;
      box-shadow: 0 0 0 1px rgba(204, 204, 204, 0.2) !important;

      input {
        outline: none;
      }
    }
  }

  svg {
    fill: #fff;
  }

  .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
  }

  .profile & {top: -0.375em;}
  &.ant-picker-focused {
    border-color: grey;
    box-shadow: 0 0 0 1px rgba(204, 204, 204, 0.2);
  }
  input {
    padding-bottom: 0.125em;
    font-size: 18px;
    font-weight: bold;
    color: #fff;

    @media (max-width: 1200px) {
      font-size: 14px;
      line-height: 140%;
    }
  }
}
.ant-select-dropdown {
  border-radius: 4px;
  background: #62707d !important;

  .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active, .ant-select-item {
    color: rgb(250 250 250 / 30%);
    border-color: #a0a0a0;
    background: #606b83;
  }
  .ant-btn-primary {
    color: #aaa;
    border-color: #ff6118;
    background: #af552c;
  }
  .ant-btn-primary:active, .ant-select-item-option-active, .ant-select-item-option-selected {
    color: #eee !important;
    border-color: #ff6118 !important;
    background: #af552c !important;
  }
  .ant-btn-primary:hover, .ant-btn-primary:focus, .ant-select-item-option-active {
    color: #ccc;
    border-color: #ff6118;
    background: #c65826;
  }
  .ant-picker-panel-container, .ant-select-dropdown {
    border-radius: 4px;
    background: #62707d !important;
  }
  /*.ant-picker-time-panel-column { // чтобы не было скроллов в тайм-пикере и были все колонки
    width: 96px;
    columns: 40px;
  }
  .ant-picker-time-panel-column:first-child {
    width: 206px;
    columns: 41px;
  }*/
  .ant-picker-time-panel-column >.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner, // для таймпикера
  .ant-picker-header, // для дейтпикера
  .ant-picker-content th,
  .ant-picker-cell-in-view {
    color: #fafafad9;
  }
  .ant-picker-time-panel-column >li.ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner,
  .ant-picker-time-panel-column >li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
    color: #666;
  }
  .ant-picker-header {
    border-bottom-color: #aaa;
  }
  .ant-picker-footer {
    border-top-color: #aaa;
  }
  .ant-picker-panel {
    border: none;
  }
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: #848a94;
  }
}
.ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid #aaa;
}
span.ant-picker-suffix svg {display: none;}
.ant-picker-clear { // положение кнопки очистки поля пикеров
  right: -21px;
  padding: 2px;
  border-radius: 50%;
  .ant-picker-range & {top: 46%; right: 7px;}
}
.ant-picker.srchDateSelect span.ant-picker-suffix::after { // значок календаря к пикеру
  content: "";
  position: relative;
  top: -3px;
  width: 30px;
  height: 28px;
  background: url(../../img/icon/icBfCalendar.png) no-repeat;
  background-size: 30px 28px;
  filter: hue-rotate(-212deg) brightness(3.4) saturate(0.25);

  @media (max-width: 1200px) {
    width: 24px;
    background-size: 24px 20px;
    height: 20px;
  }
}

.srchExtra {
  @media (max-width: 1200px) {
    display: flex;
    align-items: center;
  }
}

.srchExtra, // ===== поля - выбор города; дополнительной услуги =====
.srchCitySelect {
  margin-top: -0.75rem;
  >div.ant-select { // (стандарт селектора в местном дизайне)
    width: 100%;
    &:hover {
      .ant-select-selector {
        border-color: #8a8a8a;
      }
    }
    &-focused:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
      border-color: rgba(251, 221, 211, 0.5);
      box-shadow: 0 0 0 1px rgba(251, 221, 211, 0.25);
    }
    .ant-select-selector {
      background-color: transparent;
      border-color: transparent;
    }
    .ant-select-selection-item {
      font-size: 20px;

      @media (max-width: 1200px) {
        font-size: 14px;
        line-height: 140%;
      }
    }
    .ant-select-arrow {
      right: 2px;
      margin-top: -2px;
      font-size: 18px;
      color: #fbddd3;
    }
  }
}
.userData .rowPersData,
.srchForm .srchExtra { // поле дополнительной услуги - уточнение
  margin: 3px 3px 4px;
  padding: 0;
  border-radius: 2px;

  @media (max-width: 768px) {
    margin: 4px 0;
  }

  &.ant-select-open {
    background-color: rgba(251, 221, 211, 0.75);
    color: #fbddd3;
    .srchExtraButton,
    .srchExtraButtonText {
      color: #293c4d;
    }
  }
  &:hover .ant-select-arrow {
    color: #fbddd3;
  }
  .ant-select-arrow {
    top: 25%;
    font-size: 18px;
    color: transparent;
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #8a8a8a;
    box-shadow: 0 0 0 2px rgba(138, 138, 138, 0.2);
  }
  .ant-select-selector {
    height: auto;
    border-color: transparent;
    background-color: transparent;

    @media (max-width: 768px) {
      height: auto !important;
    }

    &:hover {
      border-color: #8a8a8a;
    }
    .ant-select-selection-item {
      line-height: 3em;
      text-align: center;
      &::after {
        content: none;
      }
    }
  }
}
.ant-select-dropdown.srchExtraOptions, // спадающий список, типичный для всех селектов по дизайну
.ant-select-dropdown.srchCityOptions {
  margin-top: -0.75rem;
  background-color: #fbddd3;
  color: #6a6c78;
  .ant-select-item {
    font-size: 18px;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #f2cabc;
    color: #332418;
  }
  .ant-select-item-option-active {
    font-size: 18px;
    background-color: #1a2835;
    color: #f9ddd1;
  }
}
.ant-select-dropdown.srchExtraOptions {
  &.srchTabs {
    flex-direction: column;
    align-items: center;
    border-radius: 2px;
    >div {width: 100%;}
  }
  .ant-select-item-option {
    font-size: 20px;
    text-align: center;
  }
  .operational {
    display: none;
  }
}
.ant-select-dropdown-hidden.srchTabs {display: none;}
.srchYouFind .srchServiceTitle {font-size: 21px;}
.srchFullResult {color: #fff;}
.srchAction:not(.ant-btn-link):not(.ant-btn-text) {
  padding: 0 0 0.3125em;
  text-align: center;
  line-height: 2.75em;
  font-size: 28px;
  font-variant: all-small-caps;
  background-color: #ff4200;
  cursor: pointer;
  &[disabled] {
    color: #6a6c78;
    background-color: #c39281;
    cursor: not-allowed;
  }
  &:hover {
    color: #6a6c78;
    background-color: #e3967c;
  }
  span {border-bottom: 1px solid #fff;}
  .srchForm >& {
    margin: 0.25em 0 1.75em;
    line-height: 1;
  }
  .servNoHeadNoFoot & {
    width: 88%;
    margin: 0.375em auto 0.375em;
    line-height: 1;
    border-radius: 2px;
  }
}
.srchServiceExtraTwo, // === Управление вторыми-третьими полями услуг по .hasNext, .stopSelect ===
.srchServiceExtraThree {display: none;}
.hasNext +.srchServiceExtraTwo,
.hasNext +.srchServiceExtraThree {display: flex;}
.srchServiceExtra.hasNext +.srchServiceExtraTwo.hasNext {
  ~.srchAction {display: none;}
  ~.srchYouFind {margin: 0.75em 0;}
}
.stopSelect .srchExtra,
.stopSelect +.srchServiceExtraTwo .srchExtra,
.srchServiceExtra.hasNext .srchExtra,
.srchServiceExtraTwo.hasNext .srchExtra {display: none;}
.stopSelect .srchService,
.stopSelect +.srchServiceExtraTwo .srchService,
.srchServiceExtra.hasNext .srchService,
.srchServiceExtraTwo.hasNext .srchService {width: calc(100% + 2px);}
   // ====================== Карусель для акций салонов и сайта =====================
.bfCarou {
  //max-width: 1530px;  // укажите ширину
  position: relative;
}
.bfCarou-list {
  overflow: hidden;
  max-width: calc(1530px - 55px); //1475
  width: calc(100vw - 60px);
  margin: 0 20px 0 -10px;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
}
.bfCarou-element {
  display: block;
  flex: 0 0 auto;
  width: 370px;  // укажите ширину блока
  text-align: center;  // укажите стиль выравнивания
  >div {
    width: calc(100% - 20px);
    margin: 0 auto;
    color: #fbddd3;
  }
}
/* Navigation item styles */
div.bfCarou-arrow-left, div.bfCarou-arrow-right {
  width: 22px;
  height: 40px;
  position: absolute;
  cursor: pointer;
  opacity: 0.6;
  z-index: 2;
  display: block;
}

.stackBlock.bfCarou.action .splide__track {
  @media (max-width: 1200px) {
    margin: 0 16px;
  }
}

.splide .carouArrowLeft {
  position: absolute;
  display: block;
  top: calc(50% - 62px); // 236px;
  left: -24px !important;
  height: 123px;
  border: 0;
  background: none;
  transform: rotate(180deg) !important;

  @media (max-width: 767px) {
    left: -12px !important;
  }
}

.splide .carouArrowRight {
  position: absolute;
  display: block;
  right: -24px !important;
  height: 123px;
  border: 0;
  background: none;

  @media (max-width: 767px) {
    right: -12px !important;
  }
}

.carouArrowRight {
  position: absolute;
  display: block;
  right: -18px;
  //top: calc(50% - 62px); // 236px;
  width: 28px !important;
  height: 123px;
}
.splide .carouArrowRight {
  position: absolute;
  display: block;
  right: -24px;
  //top: calc(50% - 62px); // 236px;
  height: 123px;
  border: 0;
  background: none;

  @media (max-width: 767px) {
    right: -12px;
  }
}
div.bfCarou-arrow-left:hover {
  opacity: 1.0;
}
div.bfCarou-arrow-right:hover {
  opacity: 1.0;
}
div.bfCarou-dots {
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  bottom: -30px;
  z-index: 2;
  text-align: center;
}
span.ant-dot {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  padding: 0;
  display: inline-block;
  background-color: #bbb;
  border-radius: 5px;
  cursor: pointer;
}
// ====================== Поиск по карте =====================
.bfSearchMap {
  color: #fffefe;
  .mapHead {
    display: flex;
    max-width: 1530px;
    width: 100%;
    margin: 0.5em 0;
    line-height: 20px;
    justify-content: space-between;

    @media (max-width: 900px) {
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 20px;
    }
  }
  .mapHeadWrapper {
    display: flex;
    justify-content: space-between;
    gap: 60px;

    @media (max-width: 1100px) {
      gap: 30px;
    }

    @media (max-width: 900px) {
      flex-basis: 100%;
      gap: unset;
      justify-content: space-between;
    }

    @media (max-width: 768px) {
      padding: 0 15px;
    }
  }
  .mapHeadEl {
    display: flex;
    flex-direction: row;
    font-size: 15px;
    font-weight: bold;

    @media (max-width: 768px) {
      font-size: 10px;
      align-items: center;
    }

    &.canBeSelected {
      cursor: pointer;
      user-select: none;
    }

    &.selected {text-decoration: underline;}
    &:nth-child(3) {flex-grow: 0.2;}
    &[data-color] >div:first-child {
      width: 18px;
      height: 18px;
      margin-right: 12px;
      border-radius: 50%;

      @media (max-width: 768px) {
        width: 12px;
        height: 12px;
        margin-right: 6px;
      }
    }
    .opened {background: #03b821;}
    .closed {background: #038bff;}
    .hasAction {background: #ff4200;}
    .notConnected {background: #898989;}
  }
  .mapDummy {
    max-width: 1880px;
    margin: 0 auto 2em;
    background-color: darkgray;
  }
}

.bt-2g__wrapper {
  position: relative;
  display: flex;
  gap: 12px;
  background-color: white;
  text-align: left;
  border-radius: 4px;
  padding: 6px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);

  .bt-2g__text-content {
    display: flex;
    flex-direction: column;
    color: #333;
    font-size: 12px;
  }

  .bt-2g__right-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .bt-2g__requests {
    cursor: pointer;
    text-decoration: underline;
    color: #666;
    font-size: 12px;
  }

  .bt-2g__price_sum {
    font-size: 16px;
    text-decoration: underline;
    color: black;
  }

  .bt-2g__order {
    width: 100%;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #FF4200;
    border-bottom-right-radius: 4px;
    font-size: 12px;
    cursor: pointer;
  }

  .bt-2g-name {
    font-size: 16px;
    transition: color .3s ease-out;
    text-decoration: underline;
    color: #666;

    &:hover {
      color: #FF4200;
    }
  }

  .bt-2g-address {
    margin-top: 14px;
  }

  .bt-2g__info {
    display: flex;
    align-items: center;
    gap: 8px;

    .bt-2g__status {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: red;

      &.green {
        background-color: #03b821;
      }
    }
  }

  .bt-2g__image {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
    }
  }

  .bt-2g-rate {
    position: relative;
    display: inline-block;
    width: fit-content;
    height: 12px;
  }

  .bt-2g-rateMain {
    display: inline-flex;
    color: #bcbcbc;
  }

  .bt-2g-rateSecondary {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 1px;
    color: black;
    display: flex;
  }

  .bt-2g-star {
    width: 12px;
    height: 12px;
  }

  .bt-2g-star-shrink {
    flex-shrink: 0;
  }
}

.bs-slidePreview {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(26, 40, 53, 0.9);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  &__close {
    position: absolute;
    top: 100px;
    right: 100px;
    width: 60px;
    height: 60px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    @media (max-width: 767px) {
      width: 40px;
      height: 40px;
      right: 2.5%;
    }

    svg {
      width: 25px;
      height: 25px;

      @media (max-width: 767px) {
        width: 15px;
        height: 15px;
      }
    }
  }

  &__inner {
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    position: relative;

    @media (min-width: 1024px) {
      max-width: 55%;
      height: fit-content;
    }

    @media (max-width: 767px) {
      max-width: 95%;
      height: fit-content;
    }
  }

  &__image {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  &__link {
    position: absolute;
    bottom: 12px;
    left: 12px;
    right: 12px;
  }

  &__btn {
    background-color: #FF4200;
    border-color: #FF4200;
    width: 100%;
    text-decoration: underline;

    &:hover, &:focus {
      background-color: #ca3400;
      border-color: #ab2d00;
    }
  }
}

.bt-2g-buttonRoot {
  width: 32px;
  box-shadow: 0 1px 3px 0 rgba(38, 38, 38, 0.5);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #fff;

  .button {
    padding: 0;
    outline: 0;
    border: none;
    cursor: pointer;
    background: #fff;
    width: 32px;
    height: 32px;
    color: #262626;
    box-sizing: border-box;
  }

  .button:hover {
    opacity: 0.7;
  }

  .button:active {
    color: #028eff;
  }
}

#bt-2g-status {
  display: none;
}

.bt-salon-on-map {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999;

  &__inner {
    position: relative;
    width: 80vw;
    height: 70vh;
  }

  .close {
    position: absolute;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%;
    z-index: 100;
    top: -50px;
    right: -50px;
  }

  #bt-salon-map-container {
    width: 80vw;
    height: 70vh;
  }
}
