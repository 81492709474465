.service {
  &List {
    display: flex;
    flex-wrap: wrap;
    padding-top: 12px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    &.empt {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;

      .createBtn {
        margin-right: 0;
        margin-bottom: 0;
        flex-direction: unset;
      }
    }
  }

  &Item {
    margin-right: 12px;
    margin-bottom: 12px;
    flex-basis: calc(25% - 12px);
    background-color: #283a4f;
    padding: 6px;
    border-radius: 4px;
    color: #fbddd2;
    transition: background-color .3s ease-out;
    position: relative;

    h5 {
      color: #fbddd2;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 2px;
    }

    &Desc {
      color: #fbddd2;
      font-size: 16px;
      display: block;
    }

    &Content {
      display: flex;
      font-size: 14px;
      justify-content: space-between;
    }

    .mods {
      position: absolute;
      top: 6px;
      right: 6px;
      align-items: center;
      opacity: 0;
      display: flex;
      transition: opacity .3s ease-out;

      .delete, .edit {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: white;
        font-size: 16px;
      }

      .edit {
        margin-right: 6px;
        background-color: rgb(3, 139, 255);
      }

      .delete {
        background-color: red;
      }
    }

    &:hover {
      background-color: #354968;

      .mods {
        opacity: 1;
      }
    }
  }
}

.createBtn {
  margin-right: 12px;
  margin-bottom: 12px;
  flex-basis: calc(25% - 12px);
  background-color: #FF4200;
  border: 1px solid #FF4200;
  padding: 6px;
  border-radius: 4px;
  color: white;
  transition: background-color .3s ease-out;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: #ca3403;
    border: 1px solid #ca3403;
  }

  &:focus {
    background-color: #ca3403;
    border: 1px solid #ca3403;
  }
}
