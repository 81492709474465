.wrapper {
  height: 800px;

  @media (max-width: 767px) {
    height: 60vh;
  }
}

[class="leaflet-popup-content"] {
  margin: 0 !important;
}

[class="leaflet-popup  leaflet-zoom-animated"] {
  bottom: 20px !important;
}

[class="leaflet-popup-tip-container leaflet-popup-tip-container_svg"] {
  display: none !important;
}

[class="leaflet-popup-content-wrapper"] {
  &::before {
    content: none;
  }
}

[class="bs-dgis-wrapper"] {
  width: 200px;
  height: 60px;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;

  [class="bs-dgis-flex"] {
    display: flex;
  }

  [class="bs-dgis-flex-col"] {
    display: flex;
    flex-direction: column;
  }
}
