// Для ЛК (Личного Кабинета)

.peopleRatingValue,
.userStars,
.salonStars {
  display: block;
  margin: 1em -4em 0 0;
  padding-right: 3em;
  font-weight: unset;
  font-size: 14px !important;

  @media (max-width: 767px) {
    margin-top: 0;
    font-size: 10px !important;
  }

  >i >img,
  >img {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 4px;

    @media (max-width: 767px) {
      width: 14px;
      height: 14px;
    }
  }
  i {position: relative; display: inline-block;}
  i.wh::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    top: 3px;
    right: 4px;
    background: url('../../img/star5pointsWhite.png');
    background-size: 20px 20px;

    @media (max-width: 767px) {
      width: 14px;
      height: 14px;
      top: 2px;
      background-size: 14px 14px;
    }
  }
  i.wh0::after {
    width: 20px;
    background-position-x: 0;
    @media (max-width: 767px) {
      width: 14px;
    }
  }
  i.wh1::after {
    width: 17px;
    background-position-x: 17px;
    @media (max-width: 767px) {
      width: 11px;
    }
  }
  i.wh2::after {
    width: 15px;
    background-position-x: 15px;
    @media (max-width: 767px) {
      width: 9px;
    }
  }
  i.wh3::after {
    width: 14px;
    background-position-x: 14px;
    @media (max-width: 767px) {
      width: 8px;
    }
  }
  i.wh4::after {
    width: 12px;
    background-position-x: 12px;
    @media (max-width: 767px) {
      width: 6px;
    }
  }
  i.wh5::after {
    width: 10px;
    background-position-x: 10px;
    @media (max-width: 767px) {
      width: 7px;
      background-position-x: 7px;
    }
  }
  i.wh6::after {
    width: 8px;
    background-position-x: 8px;
    @media (max-width: 767px) {
      width: 2px;
    }
  }
  i.wh7::after {
    width: 6px;
    background-position-x: 6px;
    @media (max-width: 767px) {
      width: 0;
    }
  }
  i.wh8::after {
    width: 5px;
    background-position-x: 5px;
    @media (max-width: 767px) {

    }
  }
  i.wh9::after {
    width: 3px;
    background-position-x: 3px;
    @media (max-width: 767px) {

    }
  }
  >div {
    line-height: 21px;
    display: inline-block;
    font-size: 21px;
    vertical-align: -6px;
    margin-left: 1em;
    color: #fff;
  }
}

.appoint .salonStars {
  @media (max-width: 767px) {
    padding-right: 0;
  }

  i.wh::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    top: 3px;
    right: 4px;
    background: url('../../img/star5pointsWhite.png');
    background-size: 20px 20px;

    @media (max-width: 767px) {
      width: 14px;
      height: 14px;
      top: 1px;
      background-size: 14px 14px;
    }
  }
  i.wh0::after {
    width: 20px;
    background-position-x: 0;
    @media (max-width: 767px) {
      width: 14px;
    }
  }
  i.wh1::after {
    width: 17px;
    background-position-x: 17px;

    @media (max-width: 767px) {
      width: 7px;
      background-position-x: 0;
    }
  }
  i.wh2::after {
    width: 15px;
    background-position-x: 15px;
    @media (max-width: 767px) {
      width: 7px;
      background-position-x: 7px;
    }
  }
  i.wh3::after {
    width: 14px;
    background-position-x: 14px;

    @media (max-width: 767px) {
      width: 7px;
      background-position-x: 7px;
    }
  }
  i.wh4::after {
    width: 12px;
    background-position-x: 12px;
    @media (max-width: 767px) {
      width: 7px;
      background-position-x: 7px;
    }
  }
  i.wh5::after {
    width: 10px;
    background-position-x: 10px;
    @media (max-width: 767px) {
      width: 7px;
      background-position-x: 7px;
    }
  }
  i.wh6::after {
    width: 8px;
    background-position-x: 8px;
    @media (max-width: 767px) {
      width: 7px;
      background-position-x: 7px;
    }
  }
  i.wh7::after {
    width: 6px;
    background-position-x: 6px;
    @media (max-width: 767px) {
      width: 7px;
      background-position-x: 7px;
    }
  }
  i.wh8::after {
    width: 5px;
    background-position-x: 5px;
    @media (max-width: 767px) {
      width: 7px;
      background-position-x: 7px;
    }
  }
  i.wh9::after {
    width: 3px;
    background-position-x: 3px;
    @media (max-width: 767px) {
      width: 7px;
      background-position-x: 7px;
    }
  }

  & > div {

    @media (max-width: 767px) {
      margin-left: 0.1em;
      font-size: 14px;
    }
  }
}

.ant-layout {
  .stackBlock {
    &.bacVw100 {
      width: 100vw;
      background-color: #293c4d;
      &.master {width: calc(100vw - 18px);} // для /salon
      >.stackIn {max-width: 1470px; margin: 0 auto;
        width: 100%;} // 1530px - 60px
    }
    &.bfSearchMap >.stackIn {max-width: 1530px; width: 100%;}
    &.bfCabinet.tabs {
      margin: 2.75em 0 0;

      @media (max-width: 767px) {
        margin-top: 4px;
      }

      .appointActual {
        @media (max-width: 1200px) {
          margin-top: -3em;
        }

        @media (max-width: 767px) {
          margin-top: -1em;
        }
      }
    }
  }
  h2 {
    font-size: 23px;
    font-weight: bold;
    color: #fbddd3;
  }
  .stackIn {
    &.bfCabinet {
      width: calc(100% - 48px);
      max-width: 1470px; // 1530px - 60px
      >h2 {
        text-align: left;
        margin: 1.25em 0 0.1875em;
        font-size: 2.125em;
        font-weight: bold;
        letter-spacing: 1px;
        color: #fbddd3;

        @media (max-width: 1200px) {
          display: none;
        }

        .pale {
          color: rgba(251, 221, 211, 0.5);
        }
        .paleError {
          color: rgba(248, 91, 42, 0.75);
        }
      }
      >.srchDescr {display: none; padding: 0;}
      >.menuHorizOver {
        padding: 0.5625em 0;
        background-color: #293c4d;
        .menuHoriz {
          >li >a,
          >li >div {
            display: inline-block;
            color: inherit;
            >.bonus,
            >.appoActive {
              display: inline;
              padding: 0.125em 0.075em 0.125em 0.325em; // : 0.125em 0.425em
              margin: -0.125em 0.1em -0.125em 0.25em; // : -0.125em -0.25em
              border-radius: 1.25em;
              color: #ff4200;
            }
          }
          >.active >div {
            border-bottom: 2px solid #fbddd2;
          }
          >:not(.active) {
            cursor: pointer;
          }
          display: flex;
          flex-direction: row;
          margin-bottom: 0;

          @media (max-width: 767px) {
            padding-left: 20px;
            padding-right: 20px;
            justify-content: space-between;
          }

          >li {
            display: inline-block;
            padding: 0 1.5em;
            line-height: 1em;
            font-size: 21px;
            color: #fbddd3;
            margin: -0.125em 0;
            transition: background-color 0.35s;

            @media (max-width: 767px) {
              font-size: 14px;
              padding: 0;
            }

            &:not(.active):hover {
              padding-top: 0.25em;
              padding-bottom: 0.25em;
              margin-top: -0.375em;
              margin-bottom: -0.375em;
              background-color: #374959;
            }
          }
        }
      }
    }
  }
  .tabs {
    .stackIn {text-align: left;}
    .tab1 {
      display: flex;
      flex-direction: column;
    }
    .tab {
      display: none;
      color: #fbddd3;
      &.active {display: block;}
      .appointListClosed,
      .appointListActive {
        background-color: #293c4d;
        margin: 1.75em -0.5em 0.5em;
        padding: 1.25em 1.25em 7em;
        border-radius: 2px;

        &.loading {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 250px;
          font-size: 64px;
          color: #ff4200;
        }
      }
    }
    .fillSpaceVert {
      min-height: 150px;
      background: #293c4d;
    }
  }
  .appoint,
  .userAccount,
  .salonAccount {
    display: flex;
    flex-direction: row;
    margin-left: 0.75em;

    @media (max-width: 1200px) {
      flex-direction: column;
    }

    .appointName {
      @media (max-width: 1200px) {
        text-align: center;
        font-size: 23px;
        font-weight: bold;
        color: #fbddd3;
      }

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
  .appointWrapper {
    display: flex;
    flex-direction: row;

    .salonData {
      h2 a {
        color: inherit;
        text-decoration: underline;
      }
      @media (max-width: 1200px) {
        gap: 12px;

        & > div {
          display: flex;
          flex-direction: column;
          margin: 0 !important;

          .date {
            line-height: 1.2;
          }
        }
      }

      @media (max-width: 767px) {
        & > div {
          .date {
            font-size: 11px;
          }
        }
      }
    }

    .salonPhotoStars {
      @media (max-width: 1200px) {
        margin-left: 12px !important;
        margin-right: 40px !important;
        margin-top: 0 !important;
      }
    }
  }
  .userAccount {
    @media (max-width: 1200px) {
      flex-direction: row-reverse;
    }

    @media (max-width: 767px) {
      margin-left: 0;
    }
  }
  .userPhoto {
    position: relative;
  }
  .userPhotoStars .ant-upload-list-picture-card { // программный клик по невидимой кнопке в этом блоке
    overflow: hidden;
    width: 0;
    height: 0;
  }
  .peopleRatingValue,
  .salonHeadStars,
  .userPhotoStars,
  .salonPhotoStars {
    min-width: 157px;
    margin-right: 75px;

    @media (max-width: 767px) {
      min-width: 85px;
      margin-right: 0;
    }

    .photo {
      overflow: hidden;
      width: 157px;
      height: 157px;
      border-radius: 50%;
      background-color: gray;

      @media (max-width: 767px) {
        width: 75px;
        height: 75px;
      }

      >img {width: 100%; height: 100%; object-fit: cover;} // TODO пропорции!
    }
    .editOver {
      position: absolute;
      right: 4px; // 12px;
      bottom: -2px; // 12px;
      width: 34px;
      height: 34px;
      padding: 6px;
      border-radius: 50%;
      background: #ff4200;
      opacity: 0.33;
      transition: opacity 0.3s;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {opacity: 1;}
    }
    .edit {
      overflow: hidden;
      width: 22px;
      height: 19px;
      font-size: 20px;
    }
    .ant-upload-picture-card-wrapper {margin: 4px -157px -2px 0;}
    .ant-upload.ant-upload-select-picture-card {
      background-color: #5a6270;
      >.ant-upload {color: #fff;}
    }
  }
  .userPhotoStars {
    @media (max-width: 1200px) {
      flex-basis: 35%;
      margin-right: 0;

      .photo {
        margin-left: auto;
        width: 108px;
        height: 108px;
      }
    }
  }
  .salonPhotoStars {margin: 1.75em 60px 0;
    .salonPhoto {position: relative;}
  }
  .actions,
  .rowActions,
  .userData,
  .salonManData,
  .salonData,
  .peopleData {
    display: flex;
    flex: 0 1 100%;
    flex-direction: column;
    font-size: 18px;

    @media (max-width: 767px) {
      font-size: 14px;
    }

    &,
    .rowCityTranspAddrTel,
    .fio,
    .rowPersData,
    .rowDescr,
    .rowPasw,
    .rowServices {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .pasw,
      .paswNew,
      .paswNewRepeat {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1 1;
        margin-right: 24px;
        border-bottom: 2px solid #8a7f7f;

        @media (max-width: 767px) {
          margin-right: 0;
        }

        .toRight {
          position: absolute;
          bottom: 0.375em;
          right: 0.5em;
          color: #fff;
          &.hide {display: none;}
        }
        input {
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 1px;
          border: none;
          background-color: transparent;
          color: #fff;
          &[type="password"] {letter-spacing: 6px;}
        }
      }
    }
    .fio {
      @media (max-width: 1200px) {
        width: fit-content;
        margin: 0;
        flex-basis: 60%;
        display: flex;
        flex-direction: column;
      }

      @media (max-width: 767px) {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
    }
    .rowPersData {
      @media (max-width: 767px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 0 !important;

        & > div {
          margin-right: 12px !important;
          flex-basis: calc(50% - 6px) !important;
        }
      }
    }
    .rowDescr,
    .rowServices {
      flex-direction: row-reverse;
      color: #fff;
      .descr,
      .services {
        display: flex;
        margin-right: -16px;
        text-align: justify;
      }
      .toRight {
        display: flex;
        align-self: flex-end;
        overflow: hidden;
        height: 15px;
        margin-bottom: 8px;
      }
    }
    .ant-btn.green,
    .ant-btn.orange {
      margin-top: 1em;
      margin-bottom: -0.25em;
      padding: 0 2.5em;
      font-weight: bold;
      border-color: #ff4200;
      border-radius: 6px;
      background-color: #ff4200;
      color: #fff;

      @media (max-width: 767px) {
        margin: 0 auto 20px auto;
      }

      .setStar {
        display: inline-block;
        .footBlock {
          padding: 0 0.5em;
        }
      }
      &:first-child {margin-top: 0.25em}
      &:disabled {
        border-color: #6a6a6a;
        background-color: #555;
        &:hover {background-color: #555;}
      }
    }
    .ant-btn.green {
      border-color: #0c940c;
      background-color: #0c940c;
      &.toMap {
        height: 19px;
        margin-left: 0.75em;
        padding: 0 0.25em;
        line-height: 16px;
        font-size: 16px;
        font-variant: all-small-caps;
        @media (max-width: 1150px) {
          &.short >span:first-child {display: none;}} // для узких экранов экономия ширины
      }
    }
    .dummy-prev-pasw {width: 0; padding: 0; opacity: 0;}
    .fio,
    .paswTitle {
      margin: -0.25em 0 0.25em;
      font-size: 23px;
      font-weight: bold;
    }
    .paswTitle {
      margin: 1.0625em 0 0;
    }
    .rowPasw .label {
      font-size: 14px;
    }
    .salonTitle,
    .lastName,
    .firstName,
    .patronymic,
    .city,
    .transp,
    .address,
    .sex,
    .birthday,
    .rowTransp,
    .fio,
    .rowPersData,
    .phone,
    .site,
    .email,
    .instagram,
    .vk,
    .fb,
    .rowPasw,
    .owner {
      margin: 0.75em 0;
    }
    .transp {line-height: 1.75;}
    .address {line-height: 1.7;}
    .salonTitleValue,
    .lastNameValue,
    .firstNameValue,
    .patronymicValue,
    .cityValue,
    .transpValue,
    .addressValue,
    .sexValue,
    .birthdayValue,
    .phoneValue,
    .siteValue,
    .emailValue,
    .instagramValue,
    .vkValue,
    .fbValue,
    .ownerValue,
    .jobOnDateValue {
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      background: transparent;
      border: none;
      color: #fff;
      &:focus {
        margin: -1px;
        border-color: gray;
        border-bottom-color: #fbddd3;
        border-width: 1px;
        border-style: solid;
        outline: none;
      }
    }
    .salonTitle,
    .lastName,
    .firstName,
    .patronymic,
    .city,
    .sex,
    .birthday,
    .email,
    .rowTransp >div,
    .rowCityTranspAddrTel >div,
    .descr,
    .services,
    .rowPersData >div,
    .jobOnDate {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1 1;
      max-width: 236px;
      margin-right: 24px;
      border-bottom: 2px solid #8a7f7f;
      .toRight {
        position: absolute;
        bottom: 0.375em;
        right: -17px;
        overflow: hidden;
        height: 14px;
        svg {font-size: 18px; fill: #fff;}
      }
    }
    .lastName,
    .firstName,
    .patronymic,
    .city,
    .sex,
    .birthday,
    .fio,
    .rowPersData {
      margin: 0.875em 0;
      .label {font-size: 14px;}
    }
    .descr, .services {
      display: flex;
      max-width: 100%;
    }
    .value {
      padding-top: 3px;
      overflow-wrap: break-word;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
    }
    .editNote {
      margin: 0.4375em 0 2.125em;
      font-size: 14px;

      @media (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
    .ant-picker.srchDateSelect {
      top: 0.125em;
      height: 1.625em;
    }
    .sex >div.ant-select:not(:first-child) {
      //border-bottom: 2px solid #fbddd3;
      .ant-select-selector {
        height: 1.75em;
        .ant-select-selection-item {
          line-height: 1.45em;
          text-align: left;
        }
      }
      .ant-select-arrow {
        top: 0.625em;
      }
    }
  }
  .act-wrp {
    flex: 1 !important;
  }
  .app-act {
    flex: 0 !important;
  }
  .userData {
    @media (max-width: 1200px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  .peopleData .servicesTitle,
  .peopleData .remarkTitle,
  .peopleData span.label,
  .appoint span.label {
    font-size: 12px;
    color: #fff;

    @media (max-width: 767px) {
      font-size: 11px;
    }
  }
  .peopleData >div >div >span:not(.label) {
    font-size: 18px;
    color: #fbddd3;
  }
  #root & .userData {
    .lastName,
    .firstName,
    .patronymic,
    .city,
    .sex,
    .birthday,
    .email,
    .phone {
      max-width: calc((100% - 74px) / 3);
      @media(max-width: 1179px) {
        max-width: calc((100% - 74px) / 2);
      }
      @media (max-width: 767px) {
        max-width: calc(50% - 18px);
      }

      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        @media (max-width: 767px) {
          padding-left: 0;
        }
      }

      .ant-picker-suffix {
        @media (max-width: 767px) {
          display: none;
        }
      }
    }
  }
  #root & .salonManData {
    .city,
    .phone {
      max-width: 13%;
      @media(max-width: 1179px){max-width: 13%;}
    }
    .transp,
    .site,
    .email {
      max-width: 14%;
      @media(max-width: 1179px){max-width: 14%;}
    }
    .address {
      max-width: 19%;
      @media(max-width: 1179px){max-width: 19%;}
    }
    .instagram,
    .vk,
    .fb,
    .owner {
      max-width: 19.5%;
      @media(max-width: 1179px){max-width: 13.5%;}
    }
  }
  .peopleClient,
  .peopleMaster,
  .appointClosed,
  .appoint {
    margin-bottom: 1.375em;
    padding: 0.75em 2.5em 1.5em 0.75em;
    background-color: #1a2835;
    border-radius: 6px;
  }
  .appoint {
    @media(max-width: 1200px) {
      padding: 20px 4px;
      box-sizing: border-box;
    }

    @media (max-width: 767px) {
      padding: 12px 4px;
      gap: 8px;
    }
  }
  .actions,
  .peopleHead,
  .rowActions {
    display: flex;
    flex-direction: column;
    flex: 1;
    .ant-btn.orange {
      height: 34px;
      padding: 0 0.5em;
      font-size: 21px;
      font-weight: normal;
      color: #fbddd3;

      &[disabled] {
        border-color: #5a5657;
        background-color: #5a5657;
        color: #b1b0b0;
      }
    }
  }
  .actions {
    .pr-act-rate {
      cursor: default;
      display: flex;
      align-items: center;
      justify-content: center;

      &-comp {
        cursor: pointer;
        color: black;

        @media (max-width: 1200px) {
          font-size: 14px;
        }

        @media (max-width: 767px) {
          font-size: 8px;

          .ant-rate-star:not(:last-child) {
            margin-right: 4px;
          }
        }

        &.rated {
          color: #ff4200;
        }
      }
    }

    @media (max-width: 1200px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 12px;
      margin-top: 12px;

      .pr-act-btn {
        flex-basis: calc(33.3% - 8px);
        margin: 0 !important;
        padding: 4px 15px !important;
        height: unset !important;
        font-size: 14px !important;
        font-weight: bold !important;
      }
    }

    @media (max-width: 767px) {
      gap: 6px;

      .pr-act-btn {
        flex-basis: calc(33.3% - 4px);
        font-size: 7px !important;
        padding: 2px !important;

        .footBlock {
          padding: 0 0.1em !important;
        }
      }
    }
  }
  .peopleHead {
    button.ant-btn.masterAdd {
      margin-top: -0.125em;
      margin-bottom: -0.25em;
      padding: 0 2.5em;
      font-weight: normal;
      border-color: #ff4200;
      border-radius: 6px;
      background-color: #ff4200;
      color: #fbddd3;
    }
    .peopleSearch {
      width: 22em;
      height: 2.25em;
      margin: -0.25em 0 0 2.5em;
      padding: 0.25em 0.5em;
      border: 1px solid #85929B;
      border-radius: 7px;
      font-size: 16px;
      letter-spacing: 0.03125em;
      background-color: #1A2835;
      color: #fbddd3;
      &:focus {
        border-color: #cfb5ad;
      }
      &:focus-visible {
        outline: none;
      }
    }
  }
}

.bs_inp_error_item {
  position: absolute;
  color: red;
  font-size: 12px;
  white-space: nowrap;
  bottom: -22px;
}

.editable {
  position: relative;
}
