.wrapper {
  box-sizing: border-box;
  overflow: hidden;
  height: calc(100vh - 172px);
}

.inner {
  width: 100%;
  height: 100%;
  background-image: url('../../img/404.png');
  background-size: cover;
  background-position: 50% 50%;

  @media (max-width: 900px) {
    background-position: 20% 50%;
  }

  @media (max-width: 767px) {
    background-position: 0 50%;
  }
}

.content {
  padding-top: 200px;
  max-width: 1530px;
  margin: 0 auto;

  @media (max-width: 1580px) {
    margin: 0 25px;
  }

  @media (max-width: 1360px) {
    padding-top: 120px;
  }

  @media (max-width: 767px) {
    padding-top: 20px;
  }

  h2, h1 {
    color: #fbddd3;
    text-transform: uppercase;
    line-height: 100%;
    margin: 0;
  }

  h2 {
    font-size: 112px;

    @media (max-width: 1360px) {
      font-size: 86px;
    }

    @media (max-width: 1000px) {
      font-size: 68px;
    }

    @media (max-width: 767px) {
      font-size: 54px;
    }
  }

  h1 {
    font-size: 306px;

    @media (max-width: 1360px) {
      font-size: 250px;
    }

    @media (max-width: 1000px) {
      font-size: 200px;
    }

    @media (max-width: 767px) {
      font-size: 154px;
    }
  }

  h4, p {
    color: white;
    max-width: 512px;

    @media (max-width: 1360px) {
      max-width: 400px;
    }

    @media (max-width: 1000px) {
      max-width: 340px;
    }

    @media (max-width: 767px) {
      max-width: 250px;
    }
  }

  h4 {
    margin: 0;
    font-size: 47px;

    @media (max-width: 1360px) {
      font-size: 38px;
    }

    @media (max-width: 767px) {
      font-size: 30px;
    }
  }

  p {
    font-size: 27px;
    padding: 0;
    line-height: 110%;

    @media (max-width: 1360px) {
      font-size: 20px;
    }

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  .link {
    text-transform: uppercase;
    color: white;
    text-decoration: underline;
    font-size: 27px;
    line-height: 110%;

    @media (max-width: 1360px) {
      font-size: 20px;
    }

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
}
