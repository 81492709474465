.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(6, 37, 68, 0.8);
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.inner {
  width: 100%;
  max-width: 1348px;
  border: 1px solid white;
  position: relative;
  background: rgba(6, 37, 68);
  padding: 8px;
  max-height: 90vh;
  overflow-y: auto;

  .title {
    display: block;
    margin: 6px;
    color: #ff4200;
    font-size: 34px;
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: -34px;

    @media (max-width: 767px) {
      font-size: 24px;
    }
  }

  [class*="srchYouFind"] {
    div {
      text-align: center;
    }
  }

  .close {
    position: sticky;
    top: 6px;
    margin-left: auto;
    right: 6px;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    cursor: pointer;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
