.wrapper {
  max-width: 310px;
  margin: 0 !important;
  padding: 0 !important;
  background-color: transparent !important;

  .servList {
    margin-bottom: 10px;
  }

  .serviceItem {
    color: white;
    background-color: #FF4200;
    text-align: center;
    padding-block: 4px;
  }

  .dateInner {
    padding-inline: 10px;
  }

  .guide {
    text-align: center;
    color: #fbddd3;
    font-size: 18px;
    margin-bottom: 14px;
  }

  .dateWrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: #fbddd3;
    margin-bottom: 20px;
  }

  .today {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .timingWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 12px;
    color: #fbddd3;

    .tagItem {
      border: 1px solid #fbddd3;
      border-right-width: 0;
      cursor: pointer;
      transition: background-color .3s ease-out;

      &:nth-of-type(4n) {
        border-right-width: 1px;
      }

      &.checked {
        background-color: #FF4200;
        color: white;
      }

      &.empty {
        border-right-width: 1px;
        background-color: #fbddd3;
        cursor: default;
        user-select: none;
      }
    }
  }
}
