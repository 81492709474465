
.salon .menuHorizOver2 {
  margin-bottom: 3em;
}
.bs-adapt-title {
  margin: 0;
  text-align: left;
  margin-left: 1em;
}
#root >.ant-layout.salon {
  .stackIn.bfCabinet:not(.salonHeadStars) > h2 {
    margin-block-start: unset;
    margin-bottom: 0.4375em;
    font-size: 3.5em;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 32px;
    }
  }
  .salonHeadStars h2 {
    margin: 0;

    @media (max-width: 767px) {
      flex-basis: 100%;
    }

    .test {opacity: 0.33;} // показ тестовых данных для индикации
  }
  .stackIn.bfCabinet > .menuHorizOver .menuHoriz {
    > .active > a {
      border-bottom: 2px dashed #fbddd2;
    }
    > .disabled > a {
      border-bottom-color: #927d75;
      color: #887e78;
    }
  }
  .stackIn.bfCabinet.userAccount {margin: 0 auto;}
  .salonHeadStars {
    display: flex;
    flex-direction: row;
    max-width: 1530px;
    margin: 2.125em auto 1.25em;

    @media (max-width: 1200px) {
      margin: 1em auto;
    }

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    .salonStars {
      margin-left: 4em;

      @media (max-width: 1200px) {
        margin-left: 1em;
      }
    }
    .votesReplies {
      display: flex;
      flex-direction: row;
      margin-top: 1.25em;
      font-size: 14px;
      color: #fbddd3;

      @media (max-width: 767px) {
        flex-basis: 100%;
      }

      .votes {
        margin-left: 5em;
        cursor: pointer;

        @media (max-width: 767px) {
          margin-left: 0;
        }
      }
      .replies {
        margin-left: 2em;
        cursor: pointer;
      }
      .replies a {text-decoration: underline; color: #fbddd3;}
    }
  }
  .salonProfile {
    display: flex;
    flex-direction: row;
    margin: 1em auto 0.5em;
    color: #fbddd3;

    @media (max-width: 1200px) {
      flex-direction: column;
    }

    @media (max-width: 767px) {
      flex-direction: column-reverse;
    }

    .salonPhoto {
      width: 488px;
      margin-right: 27px;
      color: #fbddd3;

      @media (max-width: 767px) {
        width: calc(100vw - 40px);
      }

      img {
        width: 488px;

        @media (max-width: 767px) {
          width: calc(100vw - 40px);
        }
      }
      .ant-carousel {
        .slick-dots-bottom li button {box-shadow: 1px 1px 3px #1a2835;}
        color: #fbddd3;
      }
    }
    .salonData {
      display: flex;
      text-align: left;

      @media (max-width: 767px) {
        position: relative;
      }

      .rowCityTranspAddrTel,
      .rowAddrTransp {
        display: flex;
        flex-direction: row;
        color: #fff;

        @media (max-width: 1200px) {
          flex-wrap: wrap;
        }

        @media (max-width: 767px) {
          gap: 6px;
        }

        .rowCity,
        .rowAddr {
          flex-grow: 1;
          margin: -0.125em 1em 0 0;
          padding: 0.5em 0 0 1.75em;
          background: url(../../img/geolocBigger.png) no-repeat  0.1875em 0.375em;
          background-size: 16px 27px;

          @media (max-width: 767px) {
            background: url(../../img/geolocBigger.png) no-repeat  2px 12px;
            background-size: 11px 15px;
          }
        }
        .rowTransp {
          margin: -0.125em 1em 0 0;
          padding: 0.5em 0 0 2.5em;
          background: url(../../img/eM.png) no-repeat 0 0.25em;
          background-size: 37px 27px;

          @media (max-width: 1200px) {
            white-space: nowrap;
          }

          @media (max-width: 767px) {
            background: url(../../img/eM.png) no-repeat  0 10px;
            background-size: 24px 25px;
          }
        }
      }
      .rowPhoneWeb {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0.25em 0 0.75em;
        color: #fff;

        @media (max-width: 767px) {
          flex-wrap: wrap;
          gap: 6px;
        }

        .rowPhone {
          margin: -0.125em 1em 0 0;
          padding: 0.5em 0 0 1.75em;
          background: url(../../img/phone.png) no-repeat 0 0;
          background-size: 24px 27px;

          @media (max-width: 767px) {
            background: url(../../img/phone.png) no-repeat 0 6px;
            background-size: 16px 20px;
          }
        }
        .phoneDelim {
          margin: 0 0.75em;
        }
        .rowWeb {
          display: flex;
          flex-direction: row;
          margin-right: 1em;
          padding: 0.375em 0 0 1.75em;
          background: url(../../img/globe.png) no-repeat 0 0.0625em;
          background-size: 26px 27px;

          @media (max-width: 767px) {
            background: url(../../img/globe.png) no-repeat 0 6px;
            background-size: 16px 16px;
          }

          a {color: #fff;}
        }
        .rowNet {
          display: flex;
          flex-direction: row;
          margin: 0 0 0.5em;

          @media (max-width: 767px) {
            position: absolute;
            top: -80px;
            right: 0;
          }

          .inView, .vkView, .fbView {
            width: 1.6em;
            height: 1.6em;
            margin-right: 0.5em;
            text-align: center;
            border-radius: 50%;
            background-color: #ff4200;
            &.disabled {opacity: 0.33;}
            > a {color: #fff;}
          }
        }
      }
    }
  }
  .stackBlock.action .stackIn >h2 {margin-bottom: 0.1875em;}
  .stackBlock.master .stackIn >h2 {margin-bottom: -0.1875em;}
  .master {
    color: #fbddd3;

    @media (max-width: 1200px) {
      width: 100vw;
      padding-left: 20px;
      padding-right: 20px;
    }

    .splide {margin: 0.75em 0 0.25em;}
    .bfCarou-element {
      width: 244px;
      > div.descr {
        height: 200px;
        background-color: #fbddd3;
        color: #333;
        margin: 1px 11px 0;
        padding: 0 4px 0;
      }
      >div.i {
        overflow-y: hidden;
        max-height: 250px;
      }
      >.ant-btn {
        margin: 0 11px 6px;
        width: calc(100% - 22px);
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-color: #ff4200;
        background-color: #ff4200;
        color: #fbddd3;
      }
      h4 {color: #fbddd3;}
    }
    .srchForm {
      border-radius: 1.5em;
      background-color: #1a2835;
    }
    .servByCat.tab {
      display: none;
      margin-top: -2em;
      &.active {display: block;}
      .servCat {
        columns: 439px;
        column-gap: 20px;
        column-rule: 1px outset #c7b2af;
        text-align: left;
      }
    }
    label.ant-radio-button-wrapper-disabled {
      &:hover {
        background-color: #524c49;
      }
      &>span {
        color: #786f6f;
        text-decoration-line: line-through;
      }
      &:first-child {background-color: transparent;}
    }
    .descr {
      margin-bottom: 0.875em;
    }
    .srchTabs {
      display: none; // === отключили блок табов; всё - формально в первом табе и его не переключаем ===
      margin-top: 0.125em;
    }
    .srchSalonDateTime {
      width: 88%;
      margin: -1px auto 1em;
      &Over {
        margin-bottom: 1.25em;
        background-color: #1a2835;
      }
    }
    .ant-modal-content {
      padding: 1.25em 0 2.375em;
      background-color: #1a2835;
      box-shadow: none;

      @media (max-width: 767px) {
        padding: 0;
      }
    }
    .servByCat.servNoHeadNoFoot.tab.active ~ .srchSalonDateTimeOver {
      .srchSalonDateTime {
        margin-bottom: -0.125em;
      }
      >.ant-modal-content {
        padding: 0;
        >.ant-modal-body {padding: 0 0 1em;}
      }
    }
    .splide__slide {
      h4 {
        margin: 0.125em 0 -0.25em;
        font-size: 18px;
        font-weight: bold;
      }
      > div+div {
        //height: 200px;
        background-color: #fbddd3;
        color: #333;
        margin: 1px 0 0;
        padding: 0 4px 0;
      }
      >.ant-btn {
        margin: 0 0 6px;
        width: 100%;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-color: #ff4200;
        background-color: #ff4200;
        color: #fbddd3;
      }
    }
  }
  .portfolio {
    .bfCarou-element {
      width: 298px;
      >div.i {
        overflow-y: hidden;
        max-height: 250px;
      }
    }
  }
  .splide__progress__bar {
    height: 1px;
    background-color: #666;
  }
  .advantages {
    margin-top: 3.75em;
    &.master {
      margin-top: -0.5em;
      .ant-checkbox-wrapper {font-size: 18px;}
    }
  }
}
.action {
  .splide__slide .ant-image +div {
    color: #fbddd3;
  }
}


