.wrapper {
  background-color: #1a2835;
}

.inner {
  max-width: 1470px;
  margin: 40px auto;
  padding: 0 25px;
  color: white;
}

.lightBg {
  background-color: #293c4d;
}

.title {
  margin-top: 50px;
  margin-bottom: 30px;
  font-size: 70px;
  text-decoration: underline;
  font-weight: bold;
  color: #fbddd3;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 800px) {
    font-size: 24px;
    margin-top: 24px;
    margin-bottom: 16px;
  }
}

.subTitle {
  text-decoration: underline;
  margin-top: 95px;
  margin-bottom: 60px;
  font-size: 38px;
  font-weight: bold;
  color: #fbddd3;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 800px) {
    font-size: 13px;
    margin-top: 14px;
    margin-bottom: 23px;
  }

  &.smallMg {
    margin-top: 0;
  }
}

.paragraph {
  font-size: 24px;
  padding: 0;
  font-style: italic;
  color: #fbddd3;

  @media (max-width: 800px) {
    font-size: 11px;
  }
}

mark {
  background-color: transparent;
  color: #ff4200;
  font-weight: bold;
  padding: 0;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding-bottom: 40px;

  @media (max-width: 800px) {
    gap: 30px;
  }

  &Item {
    display: flex;
    gap: 22px;

    @media (max-width: 800px) {
      gap: unset;
      display: block;
    }

    .ico {
      width: 82px;
      min-width: 82px;
      height: 82px;

      @media (max-width: 800px) {
        width: 38px;
        height: 38px;
        min-width: 38px;
        float: left;
        margin-right: 12px;
      }

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .content {
      font-size: 24px;
      font-style: italic;

      @media (max-width: 800px) {
        font-size: 12px;
      }

      .contentTitle,
      .contentText {
        display: block;
      }

      .contentTitle {
        color: #ff4200;
        font-weight: bold;
      }
    }
  }
}

.mainLink {
  color: #ff4200;
  text-decoration: none;
  font-size: 108px;
  font-weight: bold;
  text-align: center;
  display: block;
  line-height: 120%;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 25px;

  @media (max-width: 800px) {
    font-size: 40px;
  }

  &:hover {
    color: #ff4200;
  }
}
