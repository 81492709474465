

.ant-layout.salonMan .salonManData {
  ;}

.ant-layout .peopleHead {
  display: flex;
  flex-direction: column;
  &.clients {
    margin: -0.25em 0 2.25em auto;
  }
  &.masters {
    flex-direction: row-reverse;
    margin: -0.25em 0 2.25em auto;
  }
}
.peopleList {
  background-color: #293c4d;
  margin: 1.75em -0.5em 0.5em;
  padding: 1.25em 1.25em 7em;
  border-radius: 2px;
  dl {
    display: flex;
    flex-direction: row;
    // margin: 0.25em 0;
    &:first-child {
      margin: 0 auto 0 0;
      font-size: 21px;
      dd {font-weight: bold;}
    }
  }
  dt, dd {
    margin-left: 1em;
  }
}
.rowStatAdd,
.rowSortSearch {justify-content: space-between;}
.rowStatAdd,
.rowSortSearch,
.peopleClient,
.peopleMaster {
  display: flex;
  flex-direction: row;
  margin-left: 0.75em;
  .peoplePhoto {
    max-width: 187px;
    min-width: 187px;
    margin-right: 22px;
    img {
      display: block;
      max-width: 200px;
      margin: 0 auto;
    }
  }
  .loyalityAction,
  .peopleData {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 1325px;
    .rowIfoBirthCat,
    .rowDateJobTelPartici,
    .rowVisitFirstLatestCount,
    .rowServices,
    .rowAbout,
    .rowIfoBirthPhone,
    .rowDiscoFavorRate,
    .rowRemark,
    .rowActions { // аналог .rowCityTranspAddrTel, .rowPersData, ...
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin: 0.75em 0 0;
      color: #fff;
    }
    .rowActions {
      flex-direction: row;
      button.ant-btn.orange {
        margin: 0.25em 0 0 0.75em;
        &:first-child {margin-left: auto;}
        &:last-child {margin-left: 2em;}
      }
    }
    .ifo,
    .birthday,
    .cats,
    .jobOnDate,
    .phone,
    .participation,
    .visitOnDate,
    .visitLatestDate,
    .visits,
    .serviceFavor,
    .peopleRating {
      display: flex;
      flex-direction: column;
      min-width: 33%;
    }
    .birthday.birthday2,
    .participation,
    .jobOnDate {
      min-width: calc(33.3% - 1.75em);
      height: 2.75em;
      margin: 0 1.25em 0 0;
    }
    .rowRemark,
    .rowServices {
      flex-direction: column-reverse;
      position: relative;
      margin-top: 0;
      >.label {font-size: 14px;}
      .toRight {
        position: absolute;
        overflow: hidden;
        right: 1.5em;
        bottom: 0.125em;
        height: 14px;
        font-size: 17px;
      }
      .services {width: 100%;}
      textarea {
        border: 1px solid #606060;
        background-color: transparent;
        color: #fbddd3;
        &:focus {border-color: #919191; box-shadow: 0 0 0 2px rgba(255, 77, 24, 0.2);}
      }
    }
  }
  .loyalityAction {
    justify-content: space-between;
    width: 25%;
    margin: 0 -1.5em 0 1.5em;
    .action {
      display: flex;
      flex-direction: row-reverse;
    }
  }
  .participation,
  .rowAbout,
  .discountPersonal {
    max-width: none;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    border-bottom: 1px solid #524d4d;
    input {
      color: #fbddd3;
    }
    .toRight {
      position: absolute;
      bottom: 0.375em;
      right: -17px;
      overflow: hidden;
      height: 14px;
      font-size: 17px;
      color: #fff !important;
    }
  }
  .discountPersonal {
    max-width: 30%;
    margin-right: 3em;
  }
  .visitsValue,
  .discountPValue,
  .serviceFavorValue,
  .peopleRatingValue,
  .particiValue,
  .aboutValue {
    width: 100%;
    font-size: 16px;
    background: transparent;
    border: none;
    color: #fbddd3;
    &:focus {
      margin: -1px;
      border-color: gray;
      border-bottom-color: #fbddd3;
      border-width: 1px;
      border-style: solid;
      outline: none;
    }
  }
  .ant-layout & .peopleRatingValue .salonStars {
    margin-top: 0;
    i.wh:after {top: 5px;}
  }
}
.salonEvents {
  display: flex;
  flex-direction: row;
  margin-left: 0.75em;
  .salonLogo {
    ;
  }
  .salonEventsData {
    ;
  }
}
.loyalityAction .action {
  .ant-btn.green,
  .ant-btn.orange {
    //margin-top: 1em;
    margin-bottom: -0.25em;
    padding: 0 2.5em;
    font-weight: bold;
    border-color: #ff4200;
    border-radius: 6px;
    background-color: #ff4200;
    color: #fff;
    &:disabled {
      border-color: #6a6a6a;
      background-color: #555;
      &:hover {background-color: #555;}
    }
  }
  .ant-btn.orange {
    height: 34px;
    padding: 0 0.5em;
    font-size: 21px;
    font-weight: normal;
    color: #fbddd3;
    &[disabled] {
      border-color: #5a5657;
      background-color: #5a5657;
      color: #b1b0b0;
    }
  }
}

