.wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 1530px;
  margin: 0 auto;
  padding-top: 12px;
  padding-bottom: 60px;
  gap: 60px;

  @media (max-width: 1570px) {
    margin: 0 20px;
  }

  @media (max-width: 767px) {
    margin: 0 12px;
    padding-top: 0;
  }
}

.filterWrapper {
  flex-direction: column;
  gap: 40px;

  &.DESkTOP {
    display: flex;
  }

  &.TABLET {
    display: none;
  }

  &.MOBILE {
    display: none;
  }

  .filterTitle {
    font-weight: bold;
    color: white;
    font-size: 18px;
  }

  .filterButtons {
    display: flex;
    gap: 12px;

    .filterBtn {
      border: 1px solid #FF4200;
      background-color: transparent;
      color: #FF4200;

      &.primary {
        border: 1px solid transparent;
        background-color: #FF4200;
        color: #FBDDD3;
      }

      &:hover, &:focus {
        border: 1px solid #ca3403;
        color: #ca3403;

        &.primary {
          border: 1px solid transparent;
          background-color: #ca3403;
          color: #FBDDD3;
        }
      }
    }

    &.fixed {
      position: sticky;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #283a4f;
    }
  }
}

.filterItem {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &Title {
    font-weight: bold;
    font-size: 16px;
    color: #FBDDD3;
  }

  .filterPrice {
    [class*="ant-slider-track"] {
      background-color: #ca3403;
    }

    [class*="ant-slider"]:hover [class*="ant-slider-handle"]:not([class*="ant-tooltip-open"]) {
      border-color: #FF4200;
    }

    [class*="ant-slider-handle"] {
      border: 2px solid #FF4200;
    }
  }

  .filterShowAll {
    cursor: pointer;
    font-size: 14px;
  }

  .priceFilterValues {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .filterCheckItem {
    color: #FBDDD3 !important;

    [class*="ant-checkbox-wrapper"] {
      color: #FBDDD3 !important;
    }
  }

  .filterInput {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #FBDDD3;
    color: white;
    border-radius: 0;
    outline: none;
  }

  .divider {
    height: 1px;
    width: 20px;
    background-color: #FBDDD3;
  }
}

.crumb {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  color: #FBDDD3;

  a {
    text-decoration: none;
    color: #FBDDD3;
  }
}

.resultContent {
  color: #FBDDD3;
  flex: 1;

  &.pending {
    justify-content: center;
    align-items: center;
    display: flex;
    color: #FF4200;
    font-size: 64px;
  }

  .resultTitle {
    font-weight: bold;
    font-size: 24px;
    display: block;
    margin-bottom: 8px;

    @media (max-width: 1200px) {
      display: flex;
      align-items: center;
      gap: 40px;
    }

    @media (max-width: 767px) {
      font-size: 17px;
      justify-content: space-between;
    }

    .btn {
      background-color: #0c940c;
      border-color: #0c940c;
      color: white;

      &:hover, &:focus {
        background-color: #0a6a0a;
        border-color: #0a6a0a;
      }
    }
  }

  .resultShowFilters {
    font-size: 16px;
    font-weight: bold;
    color: #FF4200;
    cursor: pointer;
    text-decoration: underline;
  }

  .resultHistory {
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 12px;

    @media (max-width: 767px) {
      display: block;
      gap: unset;
    }
  }

  .resultHistoryItem {
    font-weight: bold;
    font-size: 18px;

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .resultChange {
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    margin-left: 20px;

    @media (max-width: 767px) {
      color: white;
      font-size: 7px;
      margin-left: 8px;
    }
  }

  .mobileFilters {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #fbddd3;

    .checkBox {
      color: #fbddd3;
    }

    .mobRow {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .mobFilter {
      display: flex;
      align-items: center;
      transition: color .3s ease-out;

      &.selected {
        color: white;
        text-decoration: underline;
      }
    }

    .mobBtn {
      font-weight: bold;
      color: #FF4200;
      cursor: pointer;
      text-decoration: underline;
    }

    .mobSort {
      text-align: center;
      color: white;
      flex: 1;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .resultControls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .btn {
      background-color: #0c940c;
      border-color: #0c940c;
      color: white;

      &:hover, &:focus {
        background-color: #0a6a0a;
        border-color: #0a6a0a;
      }
    }

    .resultSort {
      display: flex;
      gap: 16px;
      align-items: center;

      @media (max-width: 1200px) {
        gap: 8px;
      }

      .name {
        color: white;
        font-size: 16px;

        @media (max-width: 1200px) {
          font-size: 14px;
        }
      }

      .sortItem {
        color: #FBDDD3;
        cursor: pointer;
      }

      .select {
        color: #FBDDD3;

        [class*="ant-select-selector"] {
          @media (max-width: 1200px) {
            padding: 0 3px 0 0;
            width: fit-content;
          }
        }

        [class*="ant-select-arrow"] {
          @media (max-width: 1200px) {
            right: 0;
          }
        }

        [class*="ant-select-selection-placeholder"] {
          @media (max-width: 1200px) {
            padding-right: 8px;
          }
        }

        [class*="ant-select-arrow"] {
          color: #FBDDD3;
        }

        [class*="ant-select-selection-placeholder"] {
          color: #FBDDD3;
        }
      }
    }
  }
}

.resultList {
  margin-top: 30px;

  @media (max-width: 767px) {
    margin-top: 0;
  }
}

.resultCard {
  display: flex;
  height: 200px;
  border-radius: 4px;
  overflow: hidden;
  background-color: #283a4f;
  margin-bottom: 22px;

  .cardImg {
    width: 320px;
    height: 200px;
    background-color: #907474;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    @media (max-width: 1200px) {
      width: 200px;
      height: 200px;
    }
  }

  .cardContent {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }

  .cardInfo {
    flex: 1;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 12px;

    @media (max-width: 1200px) {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 6px;
    }
  }

  //noinspection DuplicatedCode
  .cardDescFull {
    font-size: 14px;
    color: #FBDDD3;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    box-orient: vertical;
  }

  .cardDesc {
    display: block;
    margin-bottom: 4px;
    font-size: 14px;
    color: #FBDDD3;
  }

  .cardSalonName {
    font-weight: bold;
    font-size: 24px;
    color: white;
    margin-bottom: 12px;
    display: block;

    @media (max-width: 1200px) {
      margin-bottom: 8px;
      font-size: 20px;
    }
  }

  .cardLocations {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: 12px;

    @media (max-width: 1200px) {
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  .cardLocationItem {
    display: flex;
    align-items: center;
    gap: 4px;

    .ico {
      width: 18px;
      min-width: 18px;
      height: 18px;
      font-size: 18px;
      line-height: 18px;
      background-color: red;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    span {
      font-size: 14px;
      color: white;

      @media (max-width: 1200px) {
        font-size: 12px;
      }
    }
  }

  .cardStats {
    width: 200px;
    position: relative;
    padding: 12px;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 8px;
      bottom: 8px;
      left: -1px;
      width: 1px;
      background-color: #FBDDD3;
    }
  }

  .cardRating {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .cardRateText {
    color: white;
    font-size: 18px;
  }

  .cardRateButtons {
    display: flex;
    justify-content: center;
    gap: 8px;
    font-size: 12px;
    color: #FBDDD3;
    margin-top: 8px;
    margin-bottom: 12px;

    .clickable {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .btnWrapper {
    display: flex;
    justify-content: center;
    margin-top: 22px;
  }

  .btn {
    border: 1px solid transparent;
    background-color: #FF4200;
    color: #FBDDD3;

    &:hover, &:focus {
      border: 1px solid transparent;
      background-color: #ca3403;
      color: #FBDDD3;
    }
  }

  .price {
    font-size: 32px;
    color: white;
    display: flex;
    align-items: flex-end;
    line-height: 26px;
    text-align: center;
    gap: 4px;
    justify-content: center;
    margin-top: 16px;

    span {
      font-size: 16px;
      line-height: 16px;
    }
  }
}

.resultCardMobile {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #FBDDD3;
  background-color: #283a4f;
  border: 1px solid black;
  margin-bottom: 12px;

  .cardName {
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    color: #FBDDD3;
  }

  .cardContent {
    display: flex;
  }

  .cardLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    margin-bottom: 12px;
    border-right: 1px solid #FBDDD3;
    padding-right: 8px;
    padding-left: 8px;
    min-width: 100px;

    .img {
      width: 65px;
      height: 65px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #907474;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .rateWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;

      .rateText {
        font-size: 11px;
        color: white;
      }
    }

    .cardSBtn {
      font-size: 13px;
      text-align: center;
    }

    .rate {
      font-size: 12px;
      color: #ff2f00;

      [class*="ant-rate-star"]:not(:last-child) {
        margin-right: 2px;
      }
    }
  }

  .cardRight {
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    padding-right: 8px;
    flex: 1;

    .cardFeatList {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .cardFeat {
        display: flex;
        flex-direction: column;

        .name {
          color: white;
          font-size: 7px;
        }

        .value {
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -moz-box;
          -moz-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-clamp: 2;
          box-orient: vertical;

          &Listed {
            display: flex;
            flex-direction: column;

            .cardLocationItem {
              display: flex;
              gap: 4px;

              .ico {
                margin-top: 2px;
                width: 12px;
                min-width: 12px;
                height: 12px;
                font-size: 12px;
                line-height: 12px;
                background-color: red;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              span {
                font-size: 10px;
                color: white;
              }
            }
          }
        }
      }
    }

    .cardActions {
      margin-top: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .price {
        font-size: 30px;
        color: white;
        font-weight: bold;

        span {
          font-size: 13px;
        }
      }

      .btn {
        border: 1px solid transparent;
        background-color: #FF4200;
        color: #FBDDD3;

        &:hover, &:focus {
          border: 1px solid transparent;
          background-color: #ca3403;
          color: #FBDDD3;
        }
      }
    }
  }
}

.bt-2g-rate {
  position: relative;
  display: inline-block;
  width: fit-content;
  height: 24px;
}

.bt-2g-rateMain {
  display: inline-flex;
  color: white;
}

.bt-2g-rateSecondary {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  color: #FF4200;
  display: flex;
}

.bt-2g-star {
  width: 24px;
  height: 24px;
}

.bt-2g-star-shrink {
  flex-shrink: 0;
}

.paginationWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  gap: 12px;

  .btn {
    border: 1px solid transparent;
    background-color: #FF4200;
    color: #FBDDD3;

    &:hover, &:focus {
      border: 1px solid transparent;
      background-color: #ca3403;
      color: #FBDDD3;
    }
  }
}
