.wrapper {
  background-color: #1a2835;
  margin-bottom: 40px;

  @media (max-width: 767px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(26, 40, 53, 0.9);
    z-index: 999;
  }

  mark {
    color: #ff4200 !important;
    background-color: transparent !important;
    padding: 0 !important;
  }
}

.inner {
  @media (max-width: 767px) {
    background-color: #1a2835;
    position: absolute;
    top: 40px;
    bottom: 40px;
    right: 10px;
    left: 10px;
    border: 1px solid #fbddd3;
    padding: 4px;
    overflow-x: hidden;
  }
}

.close {
  display: none;

  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 22px;
    top: 52px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: white;

    .ico {
      color: black;
      font-size: 26px;
    }
  }
}

.container {
  max-width: 1530px;
  margin: 0 auto;

  @media (max-width: 1580px) {
    margin: 0 25px;
  }

  @media (max-width: 767px) {
    margin: 0;
  }
}

h2.title {
  font-size: 56px !important;
  text-align: center;

  @media (max-width: 1350px) {
    font-size: 46px !important;
  }

  @media (max-width: 767px) {
    font-size: 26px !important;
    margin-top: 40px;
  }
}

.estimates {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.estimateValue {
  font-size: 312px;
  color: white;
  font-weight: bold;
  line-height: 250px;

  @media (max-width: 767px) {
    font-size: 110px;
    line-height: 100px;
  }
}

.estList {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.estItem {
  display: flex;
  align-items: center;
  gap: 12px;
}

.estText {
  font-size: 26px;
  color: white;
  font-weight: bold;

  @media (max-width: 767px) {
    font-size: 10px;
  }
}

.estRate {
  color: #ff4200;

  @media (max-width: 767px) {
    font-size: 10px;
  }
}

.lightBg {
  background-color: #293c4d;
}

.commentsTitle {
  font-size: 56px;
  font-weight: bold;
  color: white;
  text-align: center;
  display: block;
  margin-top: 20px;

  @media (max-width: 767px) {
    font-size: 26px !important;
  }
}

.commentsList {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
  padding-bottom: 60px;
}

.comment {
  background-color: #425a71;
  padding: 6px;
  width: 100%;

  &Head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid white;
    padding-bottom: 8px;
  }

  &RateText {
    font-size: 36px;
    font-weight: bold;
    color: white;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  &Text {
    font-size: 30px;
    color: white;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  &Value {
    font-size: 20px;
    color: white;
    line-height: 30px;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .userData {
    display: flex;
    align-items: center;
    gap: 8px;

    .name {
      font-size: 36px;
      font-weight: bold;
      color: white;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }

  .date {
    font-size: 36px;
    font-weight: bold;
    color: white;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 12px;
}

.empty {
  color: white;
}

.avatar {
  color: black;
  font-weight: bold;

  [class*="ant-avatar-string"] {
    font-size: 56px;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
}
